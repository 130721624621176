import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactLoading from "react-loading";

import axios from "axios";

import moment from "moment";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import PayForCCPlatform from "../../../../../../account/finance/communities/PayForCCPlatform";

import ViewEvent from "../view/ViewEvent";

import "./PublishEvent.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;

const publishAnEvent = "/routes/interact/publishAnEvent";

const getCommunityFinSummary = "/routes/reports/getCommunityFinSummary?"; 

const getServicePrice = "/routes/create/getServicePrice?";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const getBaandaDna = "/routes/admin/getBaandaDna?"; 
const getAnEvent = "/routes/inetract/getAnEvent?";

const eventNotificationViaEmail = process.env.REACT_APP_EVENT_VIA_EMAIL;
const eventNotificationViaEmailInc = process.env.REACT_APP_EVENT_VIA_EMAIL_INC;
const eventNotificationViaSms = process.env.REACT_APP_EVENT_VIA_SMS;
const eventNotificationViaSmsInc = process.env.REACT_APP_EVENT_VIA_SMS_INC;
const eventNotificationViaBmail = process.env.REACT_APP_EVENT_VIA_BMAIL;
const eventNotificationViaBmailInc = process.env.REACT_APP_EVENT_VIA_BMAIL_INC;
const eventPublishBaseFee = process.env.REACT_APP_BASE_SERVICE_PUBLISH_FEE;

const baandaCommunityId = process.env.REACT_APP_BAANDA_COMMUNITY_ID;
const agreementName = process.env.REACT_APP_EVENT_PUBLISH_AGREEMENT_NAME;

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timeoffset = new Date().getTimezoneOffset() / 60;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1145500000";

class PublishEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      openViewFlag: false,

      theEvent: null,
      eventId: 0,

      errMsg: "",
      errFlag: false,
      systemErr: "",
      shouldNotifyFlag: true,

      showPubErrFlag: false,

      loadingFlag: false,

      publishConfirmFlag: false,
      unpublishConfirmFlag: false,

      finalConfirmationFlag: false,

      confirmMsg:
        "On successful publication, the databses, the community, and the personal calendars will be updated; contextual members will be notified via bmail (or email), and/or texts (as specified).",

      customerCommunity: null,
      baandaCommunity: null,
      recipiants: [],
      totalFee: 0,
      totalIncFee: 0,

      emailFeeObj: null,
      emailFeeIncObj: null,
      bmailFeeObj: null,
      bmailFeeIncObj: null,
      textFeeObj: null,
      textFeeIncObj: null,

      baseServicePublishFee: 0,
      baseServicePublishCancelFee: 0,

      emailingFee: 0,
      emailingIncFee: 0,
      emailOverLimit: 0,

      textingFee: 0,
      textingIncFee: 0,
      textOverLimit: 0,

      bmailingFee: 0,
      bmailingIncFee: 0,
      bmailOverLimit: 0,

      serviceId: 0,

      agreementFlag: false,
      takePaymentFlag: false,

      showPublishButton: false,
      curEventStatus: "",
      publishRequestType: "publish", // others are cancel & re-publish

      showCancelPanel: false,
      confirmCancelFlag: false,
      theReason: "",
      isOKToCancel: false,
      // isOKToReschedule: false,
      isOKToRefund: true,

      confirmRefund: false,
      refundAmount: 0,

      discountPercent: 0,
      availableRefundBalance: 0,
      numberofReturns: 0,

      publishTaskComplete: false,
      taskCompletionMsg: "",
      pubWarningFlag: false,
      pubWarningMsg: "",
      thisAgreement: [],
      eventInfo: null,
      balMessage: "",

      ratePerShift: 0,
      noOfPrePubShifts: 0,
      canCancelRepublish: true,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let baandaCommunity = null;

    let bc = await this.getCommunityInfo(baandaCommunityId);
    if (bc.status === "success") baandaCommunity = bc.Msg;

    let customerCommunity = null;

    let cc = await this.getCommunityInfo(this.props.communityId);
    if (cc.status === "success") customerCommunity = cc.Msg;

    let recipiants = this.state.recipiants;
    let curEventStatus = "";
    if (this.props.theEvent) {
      recipiants = this.props.theEvent.sendInvitesTo;
      curEventStatus = this.props.theEvent.eventStatus;
    }

    let showPublishButton = this.state.showPublishButton;
    if (this.props.theEvent && this.props.theEvent.eventStatus === "prepmode")
      showPublishButton = true;

    await this.getBaandaDNA();

    let canCancelRepublish = false;
    if (this.props.theEvent.eventStatus !== "prepmode") {
      // console.log('In here 176 ...');

      this.props.theEvent.eventOn.days.forEach((dobj) => {
        if (moment(dobj).isAfter(moment())) canCancelRepublish = true;
      });
    }

    await this.getEventFromDB();

    await this.setState({
      deviceSize,
      // theEvent: this.props.theEvent,
      eventId: this.props.theEvent.eventId,
      openViewFlag: true,
      baandaCommunity,
      customerCommunity,
      recipiants,
      showPublishButton,
      curEventStatus,
      canCancelRepublish,
    });
  };

  getEventFromDB = async () => {
    let param = "eventId=" + this.props.theEvent.eventId;
    let url = baandaServer + getAnEvent + param;
    try {
      let evret = await axios.get(url);
      if (evret.data.status === "success") {
        this.setState({
          theEvent: evret.data.Msg,
        });
      } else {
        this.setState({
          errMsg: evret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getBaandaDNA = async () => {
    let url = baandaServer + getBaandaDna;

    try {
      let agrret = await axios.get(url);

      if (agrret.data.status === "success") {
        let agree = [];
        agrret.data.Msg.agreements.forEach((obj) => {
          if (obj.agreementName === agreementName) {
            agree = [...obj.agreementLines];
          }
        });

        await this.setState({
          thisAgreement: agree,
        });
      }
    } catch (err) {
      console.log("getBaandaDNA err:", err.message);
    }
  };

  getCommAccountBalance = async () => {
    let params = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityFinSummary + params;
    // console.log("Url: ", url);
    try {
      let retbal = await axios.get(url);
      // console.log("retbal:", retbal);
      if (retbal.data.status === "success") {
        await this.setState({
          accountBalance: parseFloat(
            parseFloat(retbal.data.Msg.totalCashInCoffer).toFixed(2)
          ),
          accErr: "",
        });
        return true;
      } else {
        await this.setState({
          accountBalance: null,
          accErr: retbal.data.Msg,
        });
        return false;
      }
    } catch (err) {
      console.log("err:", err.message);
      return false;
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  onRefundAmountChange = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });
    // console.log("e.target.value:", value);

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        itemPrice: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  checkIfNotifyNeeded = async (selEvent) => {
    // console.log("checkIfNotifyNeeded selEvent.eventType:", selEvent.eventType);
    let notifyNeeded = true;
    if (selEvent.eventType === "service") {
      if (selEvent.services) {
        // console.log(
        //   "selEvent.services.serviceFeeType:",
        //   selEvent.services.serviceFeeType
        // );
        if (selEvent.services.serviceFeeType === "catalog")
          notifyNeeded = false;
      }
    }

    return notifyNeeded;
  };

  validateForMinimalReq = async () => {
    // console.log("in validateForMinimalReq...");
    let isValid = true;
    let msg = "";

    if (this.state.theEvent.eventOn.description === "") {
      isValid = false;
      msg = "An event must have some description.";
    }

    let yesterday = moment(new Date()).add(-1, "days");
    if (this.state.theEvent.eventType === "nonservice") {
      if (this.state.theEvent.eventOn.days.length === 0) {
        isValid = false;
        msg = "No date has been specified for the 'nonservice' event. | ";
      } else {
        let dateInPast = false;
        this.state.theEvent.eventOn.days.forEach((obj) => {
          if (yesterday.isAfter(moment(obj))) {
            dateInPast = true;
          }
        });
        if (dateInPast) {
          isValid = false;
          msg =
            "One or more event date is in the past. In schedule, clear and re-schedule. | ";
        }
      }
    } else {
      let serverAllocationErr = false;
      let serviceDatesErr = false;
      if (this.state.theEvent.services.prePubShifts.length === 0) {
        isValid = false;
        msg = msg + "You need to schedule services to publish. | ";
      } else {
        this.state.theEvent.services.prePubShifts.forEach((obj) => {
          if (yesterday.isAfter(obj.shiftDate)) {
            serviceDatesErr = true;
          } else if (obj.serviceProviders.length === 0) {
            serverAllocationErr = true;
          }
        });
      }
      if (serverAllocationErr) {
        msg =
          msg +
          " You need to allocate service providers for all scheduled date-time slots. | ";
        isValid = false;
      }
      if (serviceDatesErr) {
        msg =
          msg + " One or more of your scheduled services are in the past. | ";
        isValid = false;
      }

      if (
        this.state.theEvent.services &&
        this.state.theEvent.services.serviceFeeType === "catalog" &&
        !this.state.theEvent.services.taggedCatalog
      ) {
        msg += " You need to tag the event to a catalog to be sold.";
        isValid = false;
      }
    }

    if (
      this.state.shouldNotifyFlag &&
      (this.state.theEvent.contact.name === "" ||
        this.state.theEvent.contact.email === "")
    ) {
      msg =
        msg + "Must define/confirm the contact (Your info is shown here). | ";
      isValid = false;
    }

    let notifyNeeded = await this.checkIfNotifyNeeded(this.state.theEvent);

    if (notifyNeeded) {
      if (
        this.state.theEvent.audienceType === "" ||
        this.state.theEvent.audienceType === "none"
      ) {
        msg =
          msg +
          " Must provide notification Info for the service type: " +
          this.state.theEvent.services.serviceFeeType +
          " | ";
        isValid = false;
      }
      if (
        this.state.shouldNotifyFlag &&
        this.state.theEvent.inviteMedium.email !== "noemail" &&
        this.state.theEvent.emailText === ""
      ) {
        msg = msg + "You need to provide email body/text to send emails. | ";
        isValid = false;
      }
      if (
        this.state.shouldNotifyFlag &&
        this.state.theEvent.inviteMedium.bmail &&
        this.state.theEvent.attendeeDirection === ""
      ) {
        msg = msg + "You need to provide bmail body/text to send Bmails. | ";
        isValid = false;
      }
      // if (
      //   this.state.shouldNotifyFlag &&
      //   this.state.theEvent.inviteMedium.text &&
      //   this.state.theEvent.smsText === ""
      // ) {
      //   msg = msg + "You need to provide SMS text to send texts. | ";
      //   isValid = false;
      // }
    }

    let pubWarningFlag = false;
    let pubWarningMsg = "";
    if (this.state.theEvent.sendInvitesTo.length === 0) {
      pubWarningFlag = true;
      pubWarningMsg = "You are not inviting anyone for this event.";
    }
    await this.setState({
      pubWarningFlag,
      pubWarningMsg,
    });

    return { status: isValid, Msg: msg };
  };

  getCommunityInfo = async (communityId) => {
    let parms = "communityId=" + communityId;
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("url:", url);
    let output;
    let systemErr = "";
    try {
      let retData = await axios.get(url);
      // console.log("getCommunityInfo retData: ", retData);
      if (retData.data.status === "success") {
        output = { status: "success", Msg: retData.data.Msg };
      } else {
        systemErr = retData.data.Msg + " Plese Contact Baanda Support.";
      }
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      output = { status: "error", Msg: err.message };
      systemErr = err.message + " Please Contact Baanda Support.";
    }
    await this.setState({
      systemErr,
    });
    return output;
  };

  getServiceFee = async (servicename) => {
    let communityId = 0;
    // INCLUDE this later ... it is in schema. We have to define this in 'adminstrator' for
    // people to apply and get discounts.
    let serviceObj = null;
    let isOk = "success";
    if (
      this.state.customerCommunity &&
      this.state.customerCommunity.economicHardshipFlag
    ) {
      communityId = this.props.communityId;
    }
    let param =
      "intent=" +
      this.state.customerCommunity.intent +
      "&focus=" +
      this.state.customerCommunity.intentFocus +
      "&communityId=" +
      communityId +
      "&serviceName=" +
      servicename;

    let url = baandaServer + getServicePrice + param;
    // console.log("url:", url);
    try {
      let serPrice = await axios.get(url);
      // console.log("serPrice:", serPrice);
      if (serPrice.data.status === "success") {
        serviceObj = serPrice.data.Msg;
        await this.setState({
          serviceId: serPrice.data.Msg.serviceId,
        });
      } else {
        // console.log("Pricing has not been set");
        isOk = "error";
      }
    } catch (err) {
      console.log("getServiceFee Err:", err.message);
      isOk = "error";
    }

    return { status: isOk, Msg: serviceObj };
  };

  calculatePublishFee = async () => {
    let noOfRecipiant = this.state.recipiants.length;

    let emailFeeObj = this.state.emailFeeObj;
    let emailFeeIncObj = this.state.emailFeeIncObj;
    let bmailFeeObj = this.state.bmailFeeObj;
    let bmailFeeIncObj = this.state.bmailFeeIncObj;
    let textFeeObj = this.state.textFeeObj;
    let textFeeIncObj = this.state.textFeeIncObj;

    // 1. base Email prices (eventNotificationViaEmail)
    let emailingFee = this.state.emailingFee;
    let emailingIncFee = this.state.emailingIncFee;
    let emailOverLimit = this.state.emailOverLimit;

    let textingFee = this.state.textingFee;
    let textingIncFee = this.state.textingIncFee;
    let textOverLimit = this.state.textOverLimit;

    let bmailingFee = this.state.bmailingFee;
    let bmailingIncFee = this.state.bmailingIncFee;
    let bmailOverLimit = this.state.bmailOverLimit;

    let noOfPrePubShifts = 0;
    let baseServicePublishFee = 0;
    let ratePerShift = 0;
    let notificationFlag = true;

    if (this.state.theEvent.services.taggedCatalog) {
      let bspfobj = await this.getServiceFee(eventPublishBaseFee);
      // console.log("bspfobj:", bspfobj, ' eventPublishBaseFee:', eventPublishBaseFee);
      noOfPrePubShifts = this.state.theEvent.services.prePubShifts.length;
      baseServicePublishFee =
        bspfobj.Msg.creditsCharged *
        this.state.theEvent.services.prePubShifts.length;
      ratePerShift = bspfobj.Msg.creditsCharged;
      notificationFlag = false;
    }

    // console.log('baseServicePublishFee:', baseServicePublishFee);

    if (
      this.props.theEvent.inviteMedium.email !== "noemail" &&
      notificationFlag
    ) {
      // Invoke database once only.
      if (this.state.emailFeeObj === null) {
        let emailobj = await this.getServiceFee(eventNotificationViaEmail);
        // console.log('emailObj:', emailobj);
        if (emailobj.status === "success") {
          emailFeeObj = emailobj.Msg;
          emailingFee = emailobj.Msg.creditsCharged;
        }
      }

      // 1a. incrementalEmail prices (eventNotificationViaEmailInc)(if noOfRecipiant > baseEmail blocksize)
      if (emailFeeObj && emailFeeObj.blocksize > noOfRecipiant) {
        if (this.state.emailFeeIncObj === null) {
          let eobjinc = await this.getServiceFee(eventNotificationViaEmailInc);
          if (eobjinc.status === "success") {
            emailFeeIncObj = eobjinc.Msg;
            emailOverLimit = noOfRecipiant - parseFloat(emailFeeObj.blockSize);

            let multiplier = Math.ceil(
              emailOverLimit / parseFloat(emailFeeIncObj.blockSize)
            );
            emailingIncFee =
              parseFloat(emailFeeIncObj.creditsCharged) * multiplier;
          }
        }
      }
    }
    // 2.  base Text prices (eventNotificationViaSms)
    if (this.props.theEvent.inviteMedium.text && notificationFlag) {
      // Invoke database once only.
      if (this.state.textFeeObj === null) {
        let textobj = await this.getServiceFee(eventNotificationViaSms);
        if (textobj.status === "success") {
          textFeeObj = textobj.Msg;
          textingFee = textobj.Msg.creditsCharged;
        }
      }
      // 2a. incrementalText prices (eventNotificationViaSmsInc)(if noOfRecipiant > baseEmail blocksize)
      if (textFeeObj && textFeeObj.blocksize > noOfRecipiant) {
        if (this.state.textFeeIncObj === null) {
          let tobjinc = await this.getServiceFee(eventNotificationViaSmsInc);
          if (tobjinc.status === "success") {
            textFeeIncObj = tobjinc.Msg;
            textOverLimit = noOfRecipiant - parseFloat(textFeeObj.blockSize);
            let multiplier = Math.ceil(
              textOverLimit / parseFloat(textFeeIncObj.blockSize)
            );
            textingIncFee =
              parseFloat(textFeeIncObj.creditsCharged) * multiplier;
          }
        }
      }
    }

    // 3. bmail (eventNotificationViaBmail)
    if (this.props.theEvent.inviteMedium.bmail && notificationFlag) {
      // Invoke database once only.
      if (this.state.bmailFeeObj === null) {
        let bmailobj = await this.getServiceFee(eventNotificationViaBmail);
        if (bmailobj.status === "success") {
          bmailFeeObj = bmailobj.Msg;
          bmailingFee = bmailobj.Msg.creditsCharged;
        }
      }
      // 3a. incrementalText prices (if noOfRecipiant > baseEmail blocksize)
      if (bmailFeeObj && bmailFeeObj.blocksize > noOfRecipiant) {
        if (this.state.bmailFeeIncObj === null) {
          let bobjinc = await this.getServiceFee(eventNotificationViaBmailInc);
          if (bobjinc.status === "success") {
            bmailFeeIncObj = bobjinc.Msg;
            bmailOverLimit = noOfRecipiant - parseFloat(bmailFeeObj.blockSize);
            let multiplier = Math.ceil(
              bmailOverLimit / parseFloat(bmailFeeIncObj.blockSize)
            );
            bmailingIncFee =
              parseFloat(bmailFeeIncObj.creditsCharged) * multiplier;
          }
        }
      }
    }

    let discountPercent = 0;
    let tillDateStr = "";
    let tillDate = moment();
    if (this.state.customerCommunity.discounts.serviceFeeDisountTill) {
      tillDateStr =
        this.state.customerCommunity.discounts.serviceFeeDisountTill.substring(
          0,
          10
        );
      tillDate = moment(tillDateStr, "YYYY-MM-DD");
      if (moment().isBefore(tillDate)) {
        discountPercent =
          this.state.customerCommunity.discounts.serviceFeeDisount;
      }
    }

    let preDisctotal =
      baseServicePublishFee +
      emailingFee +
      emailingIncFee +
      textingFee +
      textingIncFee +
      bmailingFee +
      bmailingIncFee;

    let totalFee = 0;
    if (discountPercent > 0) {
      let dm = Math.round(
        (parseFloat(preDisctotal) * (parseFloat(discountPercent) / 100) * 100) /
          100
      );

      totalFee = parseFloat(preDisctotal) - dm;
    } else totalFee = preDisctotal;

    await this.setState({
      baseServicePublishFee,
      emailFeeObj,
      emailFeeIncObj,
      bmailFeeObj,
      bmailFeeIncObj,
      textFeeObj,
      textFeeIncObj,
      emailingFee,
      emailingIncFee,
      emailOverLimit,
      textingFee,
      textingIncFee,
      textOverLimit,
      bmailingFee,
      bmailingIncFee,
      bmailOverLimit,
      discountPercent,
      totalFee,
      ratePerShift,
      noOfPrePubShifts,
    });
  };

  packDataForDB = async () => {
    let tr = this.state.theEvent.eventOn.timeRange;
    let startTime = 0;
    if (tr.fromAmPm === "am") {
      startTime = parseFloat(tr.fromHour) * 60 + parseFloat(tr.fromMinute);
    } else {
      startTime =
        (12 + parseFloat(tr.fromHour)) * 60 + parseFloat(tr.fromMinute);
    }

    let endTime =
      startTime +
      parseFloat(tr.durationHours) * 60 +
      parseFloat(tr.durationMinutes);
    if (endTime > 1440) {
      // 24 * 60 = 1440. It is spilling into next day.
      endTime = 1440; // Till midnight. If it spills into next day ... that part is next day's event.
    }

    let shouldNotifyFlag = this.state.shouldNotifyFlag;
    if (this.state.audienceType === "none") shouldNotifyFlag = false;

    let coopStoreId = 0;
    let coopStoreName = "";
    let coopStoreKeeper = "";

    if (this.props.store) {
      coopStoreId = this.props.store.coopStoreId;
      coopStoreName = this.props.store.displayStoreName;
      coopStoreKeeper = this.props.store.displayStoreKeeper;
    }

    // Conver prePubShifts into community calendar format.
    let commcal = [];
    if (this.state.theEvent.eventType === "service") {
      this.state.theEvent.services.prePubShifts.forEach((obj) => {
        let itExists = false;
        commcal.forEach((cfe) => {
          if (cfe.calDateString === obj.shiftDateString) itExists = true;
        });
        if (!itExists) {
          let calobj = {
            communityId: this.props.communityId,
            coopStoreId,

            calendarDate: moment(obj.shiftDateString, "MM-DD-YYYY"),
            calDateString: obj.shiftDateString,
            events: [
              {
                eventId: this.state.theEvent.eventId,
                eventName: this.state.theEvent.eventName,
                shortName: this.state.theEvent.shortName,
                eventOpenTo: this.state.theEvent.eventOpenTo,
                exposureType: this.state.theEvent.exposureType,
                eventType: this.state.theEvent.eventType,
              },
            ],
          };
          commcal.push(calobj);
        }
      });
    }

    let imageLocation = "";
    this.state.customerCommunity.fileUploads.forEach((imm) => {
      if (imm.type === "image") imageLocation = imm.location;
    });

    let eventInput = {
      communityId: this.props.communityId,
      communityName: this.props.communityName,
      coopStoreId,
      coopStoreName,
      coopStoreKeeper,
      theEvent: this.state.theEvent,
      baandaFinYYYY: this.state.baandaCommunity.finYYYY,
      baandaFinMMDD: this.state.baandaCommunity.finMMDD,
      customerFinYYYY: this.state.customerCommunity.finYYYY,
      customerFinMMDD: this.state.customerCommunity.finMMDD,
      communityMembers: this.state.customerCommunity.members,
      signBoardShort: this.state.customerCommunity.signBoardShort,
      signBoardLong: this.state.customerCommunity.signBoardLong,
      timezone,
      timeoffset,
      browserDateTime: new Date(),
      startTime,
      endTime,
      shouldNotifyFlag,
      authorBaandaId: this.props.auth.user.baandaId,
      authorName: this.props.auth.user.name,
      authorEmail: this.props.auth.user.email,
      authorCell: this.props.auth.user.cell.number,
      clientProgram: "PublishEvent.js",
      clientFunction: "handlePublishEvent",
    };

    let baandaCommunity = this.state.baandaCommunity;
    let totalFee = this.state.totalFee;

    // Create community calendar with time-slots to be used for community update.

    let input;
    if (this.props.theEvent.eventType === "service") {
      input = {
        requestType: this.state.publishRequestType, // will depend if publish, cancel, re-publish
        eventInput,
        StripeAccId: baandaCommunity.stripe.id,
        paymentAmount: totalFee,
        baandaCommunityId: baandaCommunity.communityId,
        transactionFor: "eventService",
        serviceId: this.state.serviceId,
        serviceName: eventPublishBaseFee,
        commCaption: this.state.customerCommunity.commCaption,
        imageLocation,
        description:
          "Service Fee for the community " +
          this.props.communityName +
          "'s event " +
          this.props.theEvent.eventName +
          " management services.",
        paymentInstitution: "stripe", // When we accomoadate crypto, it may be coinpayments
        payMedium: "creditcard",
        commcal,
      };
    } else {
      input = {
        requestType: this.state.publishRequestType, // will depend if publish, cancel, re-publish
        eventInput,
        imageLocation,
      };
    }

    return input;
  };

  handlePublishConfirm = async () => {
    let isValid = await this.validateForMinimalReq();

    if (isValid.status) {
      if (this.state.theEvent.eventType === "service")
        await this.calculatePublishFee();
      this.setState({
        publishConfirmFlag: true,
      });
    } else {
      this.setState({
        errMsg: isValid.Msg,
        showPubErrFlag: true,
      });
    }
  };

  exitPublishEvent = async () => {
    await this.setState({
      publishConfirmFlag: false,
    });
  };

  handlePublishConfirmExit = async () => {
    await this.setState({
      publishConfirmFlag: false,
      finalConfirmationFlag: false,
    });
  };

  handleBackFromPayment = async (data) => {
    // we flip back here.
    let msg = "";
    let showPubErrFlag = false;
    let openViewFlag = true;
    let takePaymentFlag = false;
    let publishTaskComplete = false;
    let publishConfirmFlag = true; // Check later the ramification
    let showPublishButton = this.state.showPublishButton;
    let curEventStatus = this.state.curEventStatus;
    let taskCompletionMsg = "";
    if (data === "success") {
      msg = "Payment received and receipt in mail. Published successfully.";
      showPublishButton = false;
      curEventStatus = "published";
      publishConfirmFlag = false;
      publishTaskComplete = true;
      taskCompletionMsg = "Successfully Published";
    } else if (data === "cancel") {
      // console.log("Cancelled ... no ops ... remove this line in prod");
    } else {
      msg =
        "Error: Send mail to support@baanda.com with eventId = " +
        this.state.theEvent.eventId +
        " & customer communityId = " +
        this.props.communityId;
      showPubErrFlag = true;
    }
    await this.setState({
      openViewFlag,
      takePaymentFlag,
      publishConfirmFlag,
      errMsg: msg,
      showPubErrFlag,
      showPublishButton,
      curEventStatus,
      publishTaskComplete,
      taskCompletionMsg,
    });
  };

  handleNotify = async () => {
    await this.setState((prevstate) => ({
      shouldNotifyFlag: !prevstate.shouldNotifyFlag,
    }));
  };

  handleFinalPublishConfirmation = async () => {
    await this.setState((prevstate) => ({
      finalConfirmationFlag: !prevstate.finalConfirmationFlag,
    }));
  };

  handlecloseErrorbox = async () => {
    await this.setState({
      showPubErrFlag: false,
      showCancelPanel: false,
    });
  };

  handleUnPublishConfirm = async () => {
    alert("Work on unpublish & strategy");
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleAgreementSelect = async () => {
    await this.setState((prevstate) => ({
      agreementFlag: !prevstate.agreementFlag,
    }));
  };

  handleRefundSelect = async () => {
    await this.setState((prevstate) => ({
      confirmRefund: !prevstate.confirmRefund,
    }));
  };

  handlePublishEvent = async () => {
    await this.setState({
      loadingFlag: true,
    });
    let isValid = await this.validateForMinimalReq();
    // console.log("isValid:", isValid);
    if (isValid.status) {
      let input = await this.packDataForDB();
      let url = baandaServer + publishAnEvent;
      // console.log(">>>> hp url:", url, " input:", input);
      try {
        let retpub = await axios.post(url, input);
        // console.log("retpub:", retpub);
        if (retpub.data.status === "success") {
          await this.setState({
            errMsg: "Successfully published ...",
            publishConfirmFlag: false,
            publishTaskComplete: true,
          });
          await this.setState({
            loadingFlag: false,
          });
          // this.props.returnToCaller("success");
        } else {
          await this.setState({
            errMsg: retpub.data.Msg,
            showPubErrFlag: true,
            loadingFlag: false,
          });
        }
      } catch (err) {
        console.log("handlePublishEvent err:", err.message);
        await this.setState({
          errMsg: err.message,
          showPubErrFlag: true,
          loadingFlag: false,
        });
      }
    } else {
      await this.setState({
        errMsg: isValid.Msg,
        showPubErrFlag: true,
        loadingFlag: false,
      });
    }
  };

  handlePayAndPublishEvent = async () => {
    // console.log("handlePayAndPublishEvent");
    let isValid = await this.validateForMinimalReq();
    // console.log("handlePayAndPublishEvent isValid:", isValid);
    let input = await this.packDataForDB();
    // console.log("input:", input);
    if (isValid.status) {
      if (this.state.totalFee === 0) {
        await this.handlePublishEvent();
      } else {
        this.setState({
          openViewFlag: false,
          publishConfirmFlag: false,
          takePaymentFlag: true,
          eventInfo: input,
        });
      }
    } else {
      this.setState({
        errMsg: isValid.Msg,
        showPubErrFlag: true,
        loadingFlag: false,
      });
    }
  };

  postEventPublication = async () => {
    if (this.props.theEvent.eventType === "nonservice") {
      await this.handlePublishEvent();
    }
    if (this.props.theEvent.eventType === "service") {
      await this.handlePayAndPublishEvent();
    }
  };

  validateRefundability = async () => {
    // Validate if the total amount of refund to be disbursed is
    // less than or equal to available balance in stripe
  };

  inputForCancelRepubDB = async () => {
    let daysToCancel = [];
    let today = moment();
    if (this.state.theEvent.eventType === "nonservice") {
      this.state.theEvent.eventOnPublished.daysString.forEach((obj) => {
        let evdate = moment(obj, "YYYY-MM-DD");
        if (today.isBefore(evdate)) {
          daysToCancel.push(obj);
        }
      });
    }

    if (this.state.theEvent.eventType === "service") {
      this.state.theEvent.services.publishedShifts.forEach((obj) => {
        let evdate = moment(obj.shiftDateString, "YYYY-MM-DD");
        if (today.isBefore(evdate)) {
          daysToCancel.push(obj.shiftDateString);
        }
      });
    }

    let refundAttendees = [];
    this.state.theEvent.attendees.forEach((att) => {
      if (att.payStatus === "paid") refundAttendees.push(att);
    });

    // refundAmount

    let eventInput = {
      communityId: this.props.communityId,
      communityName: this.props.communityName,
      baandaFinYYYY: this.state.baandaCommunity.finYYYY,
      baandaFinMMDD: this.state.baandaCommunity.finMMDD,
      theEvent: this.state.theEvent,
      timezone,
      timeoffset,
      refundAttendees,
      refundAmount: parseFloat(this.state.refundAmount),
      theReason: this.state.theReason,
      authorBaandaId: this.props.auth.user.baandaId,
      authorName: this.props.auth.user.name,
      authorEmail: this.props.auth.user.email,
      authorCell: this.props.auth.user.cell,
      confirmRefund: this.state.confirmRefund,

      clientProgram: "PublishEvent.js",
      clientFunction: "handleCancelRescheduleFinal",
    };

    let input = {
      requestType: this.state.publishRequestType, // will depend if publish, cancel, re-publish
      eventInput,
      daysToCancel,
    };

    return input;
  };

  processCancelNonservice = async () => {
    // console.log("======Cancel non-service =================");
    let isOKToCancel = false;
    let noOfEventsInPast = 0;
    let today = moment();
    this.state.theEvent.eventOnPublished.daysString.forEach((obj) => {
      let evdate = moment(obj, "MM-DD-YYYY");
      if (today.isBefore(evdate)) {
        isOKToCancel = true;
      } else noOfEventsInPast++;
    });
    // console.log("1. isOKToCancel:", isOKToCancel);
    let noOfAttendeePaid = 0;
    let noOfConfirmedAttendee = 0;
    let balMessage = "";
    let balance = 0;
    let isOKToRefund = true;
    this.state.theEvent.attendees.forEach((obj) => {
      if (obj.payStatus === "paid") noOfAttendeePaid++;
      if (obj.responseType === "accept") noOfConfirmedAttendee++;
    });
    if (noOfAttendeePaid > 0) {
      let balobj = await this.getCommAccountBalance();
      // console.log("balobj:", balobj);
      if (balobj && this.state.accountBalance)
        balance = this.state.accountBalance;
      let toReturn = this.state.theEvent.priceToAttend * noOfAttendeePaid;

      if (balance < toReturn) {
        isOKToRefund = false;
        balMessage =
          "Insufficiant fund to refund. You have $" +
          this.commaFormattedCurrency(balance) +
          " & " +
          " require $" +
          this.commaFormattedCurrency(toReturn);
      } else {
        balMessage =
          "To refund, you have $" +
          this.commaFormattedCurrency(balance) +
          " & " +
          " require $" +
          this.commaFormattedCurrency(toReturn);
      }
    } else isOKToCancel = true;

    balMessage += " Confirmed: " + noOfConfirmedAttendee;

    let cancelMsg;
    if (!isOKToCancel) {
      cancelMsg =
        "All your event dates are in the past. This event cannot be canceled.";
    } else {
      if (noOfEventsInPast > 0) {
        cancelMsg =
          "Some of the event dates are on the past. Only future dates of the event will be canceled.";
      } else {
        cancelMsg = "The event will be canceled for all scheduled days.";
      }
    }

    await this.setState({
      showCancelPanel: true,
      publishRequestType: "cancel",
      isOKToCancel,
      isOKToRefund,
      refundAmount: this.state.theEvent.priceToAttend * noOfAttendeePaid,
      cancelMsg,
      balMessage,
      availableRefundBalance: balance,
      numberofReturns: noOfAttendeePaid,
    });
  };

  processCancelService = async () => {
    // alert("Will proecss service cancel");
    let toRefund = false;
    let isOKToCancel = false;
    let isOKToRefund = true;
    let noOfEventsInPast = 0;
    let perCapitaRefund = 0;
    // 1. Check to see if services.taggedCatalog is non-null & services has a +ve price.
    if (
      this.state.theEvent.services.taggedCatalog &&
      this.state.theEvent.services.taggedCatalog.itemPrice > 0
    ) {
      toRefund = true;
      perCapitaRefund = this.state.theEvent.services.taggedCatalog.itemPrice;
    }

    // 3. if price ... check attendees (future date & any attendees has paid)
    let anyoneToRefund = false;
    let numberofReturns = 0;
    // let confirmedToAttend = 0;
    if (toRefund) {
      this.state.theEvent.attendees.forEach((obj) => {
        obj.dayString.forEach((elm) => {
          if (moment().isAfter(moment(elm, "YYYY-MM-DD"))) {
            if (obj.payStatus === "paid") {
              anyoneToRefund = true;
              numberofReturns++;
              // confirmedToAttend++;
              isOKToCancel = true;
            }
          } else noOfEventsInPast++;
        });
      });
    }

    if (this.state.theEvent.attendees.length === 0) isOKToCancel = true;

    if (!anyoneToRefund) toRefund = false;
    let cancelMsg;
    if (!isOKToCancel) {
      cancelMsg =
        "All your event dates are in the past. This event cannot be canceled.";
    } else {
      if (noOfEventsInPast > 0) {
        cancelMsg =
          "Some of the event dates are on the past. Only future dates of the event will be canceled.";
      } else {
        cancelMsg =
          "The event will be canceled for all scheduled days based on validity of your decision.";
      }
    }

    // 4. Check await this.getCommAccountBalance(); Set msg based on account
    let balance = 0;
    let balMessage = "No refund required. ";
    if (toRefund) {
      let balobj = await this.getCommAccountBalance();

      if (balobj) balance = this.state.accountBalance;

      let toReturn = perCapitaRefund * numberofReturns;
      if (balance < toReturn) {
        isOKToRefund = false;
        balMessage =
          "Insufficiant balance to refund in full. You have $" +
          this.commaFormattedCurrency(balance) +
          " in balance to be returned to " +
          numberofReturns +
          " confirmed attendees. The price to attend is $" +
          this.commaFormattedCurrency(perCapitaRefund);
      }
    }
    // 5. setState ... to showCancelPanel...
    this.setState({
      showCancelPanel: true,
      publishRequestType: "cancel",
      isOKToCancel,
      isOKToRefund,
      refundAmount: perCapitaRefund,
      cancelMsg,
      balMessage,
      availableRefundBalance: balance,
      numberofReturns,
    });
  };

  handleEventCancel = async () => {
    if (this.state.theEvent.eventType === "nonservice")
      await this.processCancelNonservice();
    if (this.state.theEvent.eventType === "service")
      await this.processCancelService();
  };

  exitEventCancel = async () => {
    await this.setState({
      showCancelPanel: false,
      publishRequestType: "publish",
      isOKToCancel: false,
      cancelMsg: "",
    });
  };

  handleCancelRescheduleFinal = async () => {
    await this.setState({
      loadingFlag: true,
    });

    let input = await this.inputForCancelRepubDB();
    let url = baandaServer + publishAnEvent;
    // console.log(">>>> hp url:", url, " input:", input);
    try {
      let retpub = await axios.post(url, input);
      // console.log("retpub:", retpub);
      if (retpub.data.status === "success") {
        await this.setState({
          errMsg: "Request has been processed.",
          showCancelPanel: false,
          canCancelRepublish: false,
          loadingFlag: false,
        });
      } else {
        await this.setState({
          errMsg: retpub.data.Msg,
          showPubErrFlag: true,
          loadingFlag: false,
        });
      }
    } catch (err) {
      console.log("handlePublishEvent err:", err.message);
      await this.setState({
        errMsg: err.message,
        showPubErrFlag: true,
        loadingFlag: false,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("PublishEvent...");

    // Upload Spinner _____________________________________________
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#237547"}
            height={25}
            width={25}
            className="comm-entry-spinner"
          />
          &nbsp;Please wait ...
        </div>
      );
    } else {
      uploadingSpin = null;
    }
    // Upload Panel _______________________________________________

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let closeErrorBoxButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.handlecloseErrorbox}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let buttonPanel;
    if (this.state.showPublishButton) {
      buttonPanel = (
        <div className="pub-state-lbl">
          Status: PrepMode&nbsp;&nbsp;&nbsp;
          {!this.state.publishConfirmFlag ? (
            <button
              className="btn_reg_70"
              type="button"
              onClick={this.handlePublishConfirm}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Publish
            </button>
          ) : null}
          {uploadingSpin}
          {infoModalButton}
        </div>
      );
    } else {
      if (this.state.canCancelRepublish) {
        buttonPanel = (
          <div className="unpub-state-lbl">
            Status: <font color="green">Published</font>&nbsp;&nbsp;&nbsp;
            {this.props.store ? null : (
              <button
                className="btn_reg_75"
                type="button"
                onClick={this.handleEventCancel}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Cancel
              </button>
            )}
            {uploadingSpin}
            {infoModalButton}
          </div>
        );
      } else {
        buttonPanel = (
          <div className="unpub-state-lbl">
            Status: {this.state.theEvent.eventStatus}&nbsp;&nbsp;{" "}
            {infoModalButton}
          </div>
        );
      }
    }

    let pubViewPanel;
    if (this.state.openViewFlag) {
      pubViewPanel = (
        <div>
          <ViewEvent
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.hostBaandaId}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnFromPublish}
            caller="eventcreator"
          />
        </div>
      );
    }

    let errMsgPanel;
    if (this.state.showPubErrFlag) {
      errMsgPanel = (
        <div className="publish-err-box">
          <div className="publ-error-panel-header">
            Publishing Errors & Requirements
          </div>
          <div className="text-center pub-err-msg-box-xx">
            {this.state.errMsg}
          </div>
          <div>{closeErrorBoxButton}</div>
        </div>
      );
    }

    let goPublishPanelOpsButtonPanel;
    goPublishPanelOpsButtonPanel = (
      <div className="text-center go-ops-pub-buttons">
        <Checkbox
          checked={this.state.agreementFlag}
          onChange={this.handleAgreementSelect}
        />{" "}
        I agree&nbsp;&nbsp;
        {/* {infoModalButton} */}
        <button
          className="btn_back_main"
          type="button"
          onClick={this.exitPublishEvent}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        {this.state.agreementFlag ? (
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.postEventPublication}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Post
          </button>
        ) : (
          <button className="btn_reg_60_disabled" type="button" disabled>
            Post
          </button>
        )}
      </div>
    );

    let goPublishServiceButtonPanel;
    goPublishServiceButtonPanel = (
      <div className="text-center">
        <Checkbox
          checked={this.state.agreementFlag}
          onChange={this.handleAgreementSelect}
        />{" "}
        I agree&nbsp;&nbsp;
        {/* {infoModalButton} */}
        <button
          className="btn_back_main"
          type="button"
          onClick={this.exitPublishEvent}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        {this.state.agreementFlag ? (
          <button
            className="btn_reg_90"
            type="button"
            onClick={this.postEventPublication}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Post-Send
          </button>
        ) : (
          <button className="btn_reg_90_disabled" type="button" disabled>
            Post-Send
          </button>
        )}
      </div>
    );

    let goCancelNonServiceButtonPanel = (
      <div className="text-center">
        <Checkbox
          checked={this.state.agreementFlag}
          onChange={this.handleAgreementSelect}
        />{" "}
        I agree&nbsp;&nbsp;
        <button
          className="btn_back_main"
          type="button"
          onClick={this.exitEventCancel}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        {this.state.agreementFlag ? (
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.handleCancelRescheduleFinal}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Send
          </button>
        ) : (
          <button className="btn_reg_60_disabled" type="button" disabled>
            Send
          </button>
        )}
      </div>
    );

    let pricingDisplayPanel;
    if (
      this.props.theEvent.eventType === "service" &&
      this.state.publishRequestType === "publish"
    ) {
      pricingDisplayPanel = (
        <div>
          <div className="row">
            <div className="col text-left pub-recipiant-lbl">
              Total Recipiant:&nbsp;
              <b>{this.state.recipiants.length}</b>
            </div>
          </div>
          <div className="row">
            <div className="col text-left">
              Base Publish Fee:$&nbsp;
              <b>
                {this.commaFormattedCurrency(
                  parseFloat(this.state.baseServicePublishFee)
                )}
              </b>
            </div>
          </div>
          <div className="row">
            <div className="col text-left shift-rate-lbl">
              (Total shifts: {this.state.noOfPrePubShifts} @ $
              {this.commaFormattedCurrency(this.state.ratePerShift)}/shift)
            </div>
          </div>
          <div className="row">
            <div className="col text-left">
              Email Price:$&nbsp;
              <b>
                {this.commaFormattedCurrency(
                  parseFloat(this.state.emailingFee)
                )}
              </b>
            </div>
          </div>
          {this.state.emailingIncFee !== 0 ? (
            <div className="row">
              <div className="col text-left">
                (E) Over by {this.state.emailOverLimit}:$&nbsp;
                <b>
                  {this.commaFormattedCurrency(
                    parseFloat(this.state.emailingIncFee)
                  )}
                </b>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col text-left">(E) Over limit Fee: 0.00</div>
            </div>
          )}
          <div className="row">
            <div className="col text-left">
              Texting Price:$&nbsp;
              <b>
                {this.commaFormattedCurrency(parseFloat(this.state.textingFee))}
              </b>
            </div>
          </div>
          {this.state.textingIncFee !== 0 ? (
            <div className="row">
              <div className="col text-left">
                (T) Over by {this.state.textOverLimit}:$&nbsp;
                <b>
                  {this.commaFormattedCurrency(
                    parseFloat(this.state.textingIncFee)
                  )}
                </b>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col text-left">(T) Over limit Fee: 0.00</div>
            </div>
          )}
          <div className="row">
            <div className="col text-left">
              Bmail Price:$&nbsp;
              <b>
                {this.commaFormattedCurrency(
                  parseFloat(this.state.bmailingFee)
                )}
              </b>
            </div>
          </div>
          {this.state.bmailingIncFee !== 0 ? (
            <div className="row">
              <div className="col text-left">
                (B) Over by {this.state.bmailOverLimit}:$&nbsp;
                <b>
                  {this.commaFormattedCurrency(
                    parseFloat(this.state.bmailingIncFee)
                  )}
                </b>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col text-left">(B) Over limit Fee: 0.00</div>
            </div>
          )}
          {this.state.discountPercent > 0 ? (
            <div className="text-left pub-dicsount-col">
              Init Discount:&nbsp;{this.state.discountPercent}&nbsp;%
            </div>
          ) : (
            <div className="text-left">-----------------------</div>
          )}
          <div className="row">
            <div className="col text-left pub-total-fee">
              Total Fee:$&nbsp;
              <b>{this.commaFormattedCurrency(this.state.totalFee)}</b>
            </div>
          </div>
          <div className="row service-pub-buttons">
            <div className="col text-center">{goPublishServiceButtonPanel}</div>
          </div>
        </div>
      );
    }

    if (
      this.props.theEvent.eventType === "nonservice" &&
      this.state.publishRequestType === "publish"
    ) {
      let maxEarn =
        this.props.theEvent.priceToAttend *
        this.props.theEvent.sendInvitesTo.length;
      pricingDisplayPanel = (
        <div className="text-center non-service-pub-content">
          <div className="text-center non-service-pub-header">
            Publish the Event
          </div>
          <div className="row">
            <div className="col non-service-pub-body">
              Publishing is Free. <br />
              {maxEarn === 0
                ? null
                : " Your expected revenue:$ " +
                  this.commaFormattedCurrency(maxEarn)}
            </div>
          </div>
          {/* {uploadingSpin} */}
          <div className="row">
            <div className="col non-service-pub-buttons">
              {goPublishPanelOpsButtonPanel}
            </div>
          </div>
        </div>
      );
    }

    if (
      this.props.theEvent.eventType === "nonservice" &&
      (this.state.publishRequestType === "cancel" ||
        this.state.publishRequestType === "reschedule")
    ) {
      let atLeastOneConfirmedAttended = false;
      this.state.theEvent.attendees.forEach((att) => {
        if (att.responseType === "accept") atLeastOneConfirmedAttended = true;
      });

      pricingDisplayPanel = (
        <div className="text-center non-service-pub-content">
          <div className="text-center non-service-pub-header">
            {this.state.publishRequestType === "cancel"
              ? "Cancel"
              : "Republish"}
            &nbsp;this Event
          </div>
          <div className="text-center reason-msg-entry">
            Provide a reson for the cancelation
          </div>
          <div className="row">
            <div className="col non-service-pub-body">
              <textarea
                name="theReason"
                maxLength="500"
                placeholder="Type in the reason ..."
                rows="4"
                wrap="hard"
                spellCheck="true"
                className="cancel_repub_reason"
                onChange={this.onChange}
                value={this.state.theReason}
                // required
              />
            </div>
          </div>
          <div className="text-center reason-char-count">
            Max 500 chars. Used {this.state.theReason.length}
          </div>

          {parseFloat(this.state.theEvent.priceToAttend) > 0 &&
          atLeastOneConfirmedAttended &&
          this.state.publishRequestType === "cancel" ? (
            <div className="text-center attendee-refund-row">
              <Checkbox
                checked={this.state.confirmRefund}
                onChange={this.handleRefundSelect}
              />{" "}
              Check to refund&nbsp;&nbsp;&nbsp;&nbsp;Amt:$
              {this.state.confirmRefund ? (
                <input
                  name="refundAmount"
                  type="number"
                  value={this.state.refundAmount}
                  onChange={this.onRefundAmountChange}
                  size="20"
                  className="event-attendee-refund"
                  step=".01"
                  placeholder="0.00"
                  autoComplete="off"
                />
              ) : null}
            </div>
          ) : null}
          <div className="row">
            <div className="col non-service-pub-buttons">
              {goCancelNonServiceButtonPanel}
            </div>
          </div>
        </div>
      );
    }

    let confirmPanel;
    if (this.state.publishConfirmFlag) {
      confirmPanel = (
        <div className="publish-confirm-box">
          <div className="row">
            <div className="col-md-6 text-right">
              <div className="text-center event-pub-agreement-head">
                Agreements
              </div>
              <div className="show-event-agreement-box ">
                {this.state.thisAgreement.map((post, i) => (
                  <div key={i}>
                    <div className="event-publish-agr-lines">
                      {post.seqno}.&nbsp; {post.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 brd-pub-pricing">
              {pricingDisplayPanel}
            </div>
          </div>
          {!this.state.pubWarningFlag ? (
            <div className="row pub-warning-row">
              <div className="col text-center pub-warning-msg">
                {this.state.pubWarningMsg}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    let publishOpsPanel;
    if (this.state.theEvent) {
      publishOpsPanel = (
        <div>
          <div>{confirmPanel}</div>
          {errMsgPanel}
          <div className="row publish-control-row">
            {this.state.theEvent &&
            this.state.theEvent.eventStatus === "published" ? (
              <div className="col-md-6 text-center should-notify">&nbsp;</div>
            ) : (
              <div className="col-md-6 text-center should-notify">
                <Checkbox
                  checked={this.state.shouldNotifyFlag}
                  onChange={this.handleNotify}
                />
                &nbsp;&nbsp;
                {this.state.theEvent.eventType === "nonservice"
                  ? "Notify invitees after publishing."
                  : "Notify service providers & invitees."}
                &nbsp;
              </div>
            )}

            <div className="col-md-6 text-center">{buttonPanel}</div>
          </div>
          <div className="text-center sys-err-msg">{this.state.systemErr}</div>
        </div>
      );
    }

    let cancelPanel;
    if (this.state.showCancelPanel) {
      if (this.state.isOKToCancel) {
        // if (this.state.isOKToCancel || this.state.isOKToReschedule) {

        cancelPanel = (
          <div className="publish-confirm-box">
            <div className="row">
              <div className="col-md-6 show-event-agreement-box text-right">
                <p align="justify">
                  Please read the agreement below and consent to proceed:
                </p>
                <ul>
                  <li>
                    <p align="justify" className="brd-pub-conditions">
                      {this.state.publishRequestType === "cancel"
                        ? "All eligible entries of the event will be removed from calendars."
                        : "All eligible entries of the event will be rescheduled."}
                    </p>
                  </li>
                  <li>
                    <p align="justify" className="brd-pub-conditions">
                      The{" "}
                      {this.state.publishRequestType === "cancel"
                        ? "cancel"
                        : "rescheduled"}{" "}
                      notice will be sent per your original setup by default.
                      You can modify the event's settings to alter notification
                      targets & medium.
                    </p>
                  </li>
                  {this.state.noOfEventsInPast > 0 ? (
                    <li>
                      <p
                        align="justify"
                        className="brd-pub-conditions-allcancancel"
                      >
                        <format color="brown">{this.state.cancelMsg}</format>
                      </p>
                    </li>
                  ) : (
                    <li>
                      <p
                        align="justify"
                        className="brd-pub-conditions-allcancancel"
                      >
                        {this.state.cancelMsg}
                      </p>
                    </li>
                  )}
                  {this.state.balMessage === "" ? null : (
                    <li>
                      <p
                        align="justify"
                        className="brd-pub-conditions-allcancancel"
                      >
                        {this.state.balMessage}
                      </p>
                    </li>
                  )}
                </ul>
              </div>

              <div className="col-md-6 brd-pub-pricing">
                {pricingDisplayPanel}
              </div>
            </div>
          </div>
        );
      } else {
        cancelPanel = (
          <div className="publish-confirm-box">
            <div className="text-center cancel-not-allowed-msg">
              {this.state.cancelMsg}
            </div>
            {closeErrorBoxButton}
          </div>
        );
      }
    }

    let outputPanel;
    if (this.state.takePaymentFlag) {
      console.log('eventInfo:', this.state.eventInfo)
      outputPanel = (
        <div>
          <PayForCCPlatform
            inputData={this.state.eventInfo}
            deviceSize={this.state.deviceSize}
            paymentAmount={parseFloat(this.state.totalFee)}
            handleExit={this.handleBackFromPayment}
            caller="event"
          />
        </div>
      );
    }

    if (
      this.props.theEvent.eventType === "nonservice" &&
      this.state.publishRequestType === "publish"
    ) {
      outputPanel = (
        <div className="text-center event-pub-msg">
          {pubViewPanel}
          {!this.state.publishTaskComplete
            ? publishOpsPanel
            : "Successfully Published."}
        </div>
      );
    }

    if (
      this.props.theEvent.eventType === "nonservice" &&
      this.state.publishRequestType === "cancel"
      // || this.state.publishRequestType === "reschedule")
    ) {
      outputPanel = (
        <div>
          {pubViewPanel}
          {cancelPanel}
          {publishOpsPanel}
          {!this.state.showPubErrFlag ? (
            <div
              className={
                this.state.errFlag
                  ? "text-center event-view-msg-err"
                  : "text-center event-view-msg"
              }
            >
              {this.state.errMsg}
            </div>
          ) : null}
        </div>
      );
    }

    if (
      this.props.theEvent.eventType === "service" &&
      this.state.publishRequestType === "publish" &&
      !this.state.takePaymentFlag
    ) {
      outputPanel = (
        <div>
          {pubViewPanel}
          {/* {publishOpsPanel} */}
          {!this.state.publishTaskComplete ? (
            publishOpsPanel
          ) : (
            <div className="publish-task-complete">
              {this.state.taskCompletionMsg}
            </div>
          )}
        </div>
      );
    }

    if (
      this.props.theEvent.eventType === "service" &&
      this.state.publishRequestType === "cancel"
    ) {
      outputPanel = (
        <div>
          {pubViewPanel}
          {cancelPanel}
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

PublishEvent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PublishEvent));
