import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ReactLoading from "react-loading";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import ManageTarget from "../buyTarget/ManageTarget";

import PayForCCPlatform from "../../../../../../account/finance/communities/PayForCCPlatform";

import "./PublishBroadcast.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getGroupsOfCommunity = "/routes/dashboard/getGroupsOfCommunity?";
const getBroadcastPrice = "/routes/interact/getBroadcastPrice?";
const getCouponCode = "/routes/interact/getCouponCode?";
// const verifyItemCoupon = "/routes/interact/verifyItemCoupon?";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const broadcastACampign = "/routes/interact/broadcastACampign";
const checkIfTargetListExists = "/routes/interact/checkIfTargetListExists?";
const checkIfCampaignNameExist = "/routes/interact/checkIfCampaignNameExist?";
const getCommunityFooter = "/routes/interact/getCommunityFooter?";
const getExistingTargetNames = "/routes/interact/getExistingTargetNames?";
const getCouponItems = "/routes/interact/getCouponItems?";
const getBaandaDna = "/routes/admin/getBaandaDna?";
const getABroadcast = "/routes/interact/getABroadcast?";

const baandaCommunityId = process.env.REACT_APP_BAANDA_COMMUNITY_ID;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "0000000000";

class PublishBroadcast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailTargetType: "customer",
      groupNames: [],
      searchGroupName: "",
      selectedGroup: null,

      showPurchaseBoxFlag: false,
      showGroupBoxFlag: false,
      showCustomerBoxFlag: true,
      showUploadBoxFlag: false,
      showPriviousTargetList: false,
      showTestList: false,

      showCouponFlag: false,

      manageBroadcastFlag: true,
      finalBroadcastFlag: false,

      targetListType: "new",
      selheight: 1,
      selheighttg: 1,
      selheightCoup: 1,

      couponType: "nocoupon",
      couponCode: "",
      couponNote: "",
      targetName: "",
      campaignName: "",
      coupons: [],
      selectedCoupon: null,
      selectedTargetList: [],

      loadedFile: [],
      itemId: 0,

      targetGeneratedFlag: false,

      pricingDoneFlag: false,
      totalFee: 0,
      input: null,

      errMsg: "",
      errFlag: false,
      priceErrMsg: "",
      priceErrFlag: false,

      baandaCommunity: null,
      paymentAmount: 0,
      loadingFlag: false,
      mktDiscount: 0,
      broadcastWaitMsg: "",
      serviceId: 0,
      community: null,
      itemSelectionFlag: false,
      selectedItem: null,

      itemErrMsg: "",
      itemErrFlag: false,
      items: [],
      itemName: "",
      validTillDate: new Date(),

      purchaseTargetFlag: false,
      theBroadcast: null,

      forBaandaStore: true,
      nonBaandaWebSite: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.handleSearchGroupName();
    await this.GetBaandaCommunityInfo();
    await this.getBizCommunityInfo();

    await this.setState({
      deviceSize,
    });
  };

  getBizCommunityInfo = async () => {
    let parms = "communityId=" + parseFloat(this.props.communityId);
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("GetBaandaCommunityInfo url:", url);
    try {
      let retData = await axios.get(url);
      // console.log("Community retData: ", retData);
      if (retData.data.status === "success") {
        this.setState({
          community: retData.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg:
            "Business community not found for communityId = " +
            this.props.communityId +
            " Err:" +
            retData.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  GetBaandaCommunityInfo = async () => {
    let parms = "communityId=" + parseFloat(baandaCommunityId);
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("GetBaandaCommunityInfo url:", url);
    try {
      let retData = await axios.get(url);
      // console.log("Community retData: ", retData);
      if (retData.data.status === "success") {
        this.setState({
          baandaCommunity: retData.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg:
            "Baanda Community not found. Fatal Error. " + retData.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  isCouponInDateRange = (cp) => {
    // console.log("isCouponInDateRange cp:", cp);
    // console.log("this.state.couponType:", this.state.couponType);
    let today = new Date().getTime();
    if (this.state.couponType === "storewide") {
      let todate = new Date(cp.toDate).getTime();
      let fromDate = new Date(cp.fromDate).getTime();
      if (today <= todate && today >= fromDate) {
        return true;
      } else return false;
    }

    if (this.state.couponType === "baandarent") {
      return true;
    }
  };

  handleCouponFetch = async () => {
    // console.log("in handle Coupon Fetch ...");
    let params =
      "communityId=" +
      this.props.communityId +
      "&couponType=" +
      this.state.couponType;
    let url = baandaServer + getCouponCode + params;
    // console.log("handleCouponFetch... url:", url);
    try {
      let copret = await axios.get(url);
      // console.log("copret.data:", copret.data);
      if (copret.data.status === "success") {
        let coup = [];
        copret.data.Msg.forEach((cp) => {
          if (this.isCouponInDateRange(cp)) coup.push(cp);
        });
        this.setState({
          errFlag: false,
          errMsg: "",
          coupons: coup,
          showCouponFlag: true,
        });
      } else {
        this.setState({
          errFlag: true,
          errMsg: copret.data.Msg,
          showCouponFlag: false,
        });
      }
    } catch (err) {
      this.setState({
        errFlag: true,
        errMsg: err.message,
        showCouponFlag: false,
      });
    }
  };

  handlePricing = async () => {
    // console.log("handle pricing....");
    let params;
    let targetName = "";
    if (this.state.emailTargetType === "customer") {
      params =
        "communityId=" +
        this.props.communityId +
        "&targetType=" +
        this.state.emailTargetType;
      targetName = "customer";
    }
    if (this.state.emailTargetType === "group") {
      params =
        "communityId=" +
        this.props.communityId +
        "&targetType=" +
        this.state.emailTargetType +
        "&groupId=" +
        this.state.selectedGroup.groupId;
      targetName = this.state.selectedGroup.groupName;
    }

    if (this.state.emailTargetType === "upload") {
      // console.log("here ...");
      params =
        "communityId=" +
        this.props.communityId +
        "&targetType=" +
        this.state.emailTargetType +
        "&uploadFileSize=" +
        this.state.loadedFile.length;
    }

    if (this.state.emailTargetType === "existinglist") {
      // console.log("here ...this.state.selectedTarget:", this.state.targetName);
      params =
        "communityId=" +
        this.props.communityId +
        "&targetType=" +
        this.state.emailTargetType +
        "&targetName=" +
        this.state.targetName;
      targetName = this.state.targetName;
    }

    let url = baandaServer + getBroadcastPrice + params;
    // console.log("url:", url);
    try {
      let prret = await axios.get(url);
      // console.log("prret.data:", prret.data);
      if (prret.data.status === "success") {
        if (prret.data.Msg.numberOfTarget > 0) {
          let message = "";

          // REMEMBER TO MAKE it === instead of != later on...
          let discount = "";
          let discountPer = 0;
          let totalFee = 0;
          // console.log("prret.data.Msg.mktcoupons:", prret.data.Msg.mktcoupons);
          if (prret.data.Msg.mktcoupons) {
            // console.log("in discount...");
            discount =
              " | Discount (till:" +
              prret.data.Msg.mktcoupons.toDate.substring(0, 10) +
              ") " +
              this.commaFormattedCurrency(
                prret.data.Msg.mktcoupons.percentDiscount
              ) +
              "%";
            discountPer = parseFloat(prret.data.Msg.mktcoupons.percentDiscount);
          }
          if (
            parseFloat(this.props.communityId) !== parseFloat(baandaCommunityId)
          ) {
            let payAmt =
              parseFloat(prret.data.Msg.basePrice) +
              parseFloat(prret.data.Msg.overBaseVol);

            if (prret.data.Msg.mktcoupons) {
              let today = new Date().getTime();
              let fromdt = new Date(
                prret.data.Msg.mktcoupons.fromDate
              ).getTime();
              let todt = new Date(prret.data.Msg.mktcoupons.toDate).getTime();
              if (fromdt <= today && todt >= today) {
                totalFee =
                  payAmt *
                  (1 -
                    parseFloat(prret.data.Msg.mktcoupons.percentDiscount) /
                      100);
              } else {
                totalFee = payAmt;
              }
            } else {
              totalFee = payAmt;
            }
            message =
              "Quote: Base= $" +
              this.commaFormattedCurrency(prret.data.Msg.basePrice) +
              " | Over base volume= $" +
              this.commaFormattedCurrency(prret.data.Msg.overBaseVol) +
              " | No of Targets=" +
              parseFloat(prret.data.Msg.numberOfTarget) +
              discount +
              " | Total=$" +
              this.commaFormattedCurrency(totalFee);
          } else {
            message =
              "Quote:" +
              " No of Targets=" +
              prret.data.Msg.numberOfTarget +
              " | Total =$" +
              this.commaFormattedCurrency(0);
          }
          // console.log("totalFee:", totalFee);
          this.setState({
            pricing: prret.data.Msg,
            serviceId: parseFloat(prret.data.Msg.serviceId),
            pricingDoneFlag: true,
            priceErrMsg: message,
            priceErrFlag: false,
            // totalFee:
            //   parseFloat(prret.data.Msg.basePrice) +
            //   parseFloat(prret.data.Msg.overBaseVol),
            targetName,
            totalFee,
            mktDiscount: discountPer,
          });
        } else {
          this.setState({
            pricing: prret.data.Msg,
            pricingDoneFlag: true,
            priceErrMsg: "There are no target records for the scenario.",
            priceErrFlag: false,
            totalFee: 0,
            mktDiscount: 0,
          });
        }
      } else {
        this.setState({
          pricing: null,
          pricingDoneFlag: false,
          priceErrMsg: prret.data.Msg,
          priceErrFlag: true,
          totalFee: 0,
          mktDiscount: 0,
        });
      }
    } catch (err) {
      this.setState({
        priceErrMsg: err.message,
        priceErrFlag: true,
        totalFee: 0,
      });
    }
  };

  handleSearchGroupName = async () => {
    // console.log("handleSearchGroupName ...");
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&groupName=" +
        this.state.searchGroupName;
      let url = baandaServer + getGroupsOfCommunity + params;
      let grpret = await axios.get(url);
      // console.log("grpret:", grpret);
      if (grpret.data.status === "success") {
        if (grpret.data.Msg.length > 0) {
          this.setState({
            groupNames: grpret.data.Msg,
            errMsg: "",
            errFlag: false,
          });
        } else {
          if (this.state.emailTargetType === "group") {
            this.setState({
              groupNames: [],
              errMsg:
                "No groups found. Please define groups in Admin to market to.",
              errFlag: true,
            });
          } else {
            this.setState({
              groupNames: [],
              errMsg: "",
              errFlag: false,
            });
          }
        }
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  getExistingTargetNames = async () => {
    let params = "communityId=" + this.props.communityId;
    let url = baandaServer + getExistingTargetNames + params;
    try {
      let tllist = await axios.get(url);
      if (tllist.data.status === "success") {
        this.setState({
          selectedTargetList: tllist.data.Msg,
        });
        return { status: "success", Msg: "" };
      } else {
        return { status: "error", Msg: tllist.data.Msg };
      }
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  handleEmailTargetType = async (e) => {
    let value = e.target.value;
    // console.log("value:", value);
    let showPurchaseBoxFlag = false;
    // let showGroupBoxFlag = false;
    let showCustomerBoxFlag = false;
    // let showUploadBoxFlag = false;
    let showPriviousTargetList = false;
    let showTestList = false;
    let manageBroadcastFlag = this.state.manageBroadcastFlag;
    let allok = true;
    let targetName = "";
    let purchaseTargetFlag = false;
    let msg = "";
    let selheighttg = 1;
    if (value === "manage") showPurchaseBoxFlag = true;
    // if (value === "group") showGroupBoxFlag = true;
    if (value === "customer") {
      showCustomerBoxFlag = true;
      targetName = "customer";
    }
    if (value === "customer") {
      showCustomerBoxFlag = true;
      targetName = "customer";
    }
    if (value === "broadcastTest") showTestList = true;
    // if (value === "upload") showUploadBoxFlag = true;
    if (value === "existinglist") {
      let gettl = await this.getExistingTargetNames();
      if (gettl.status === "error") {
        allok = false;
        msg = gettl.Msg;
      } else {
        showPriviousTargetList = true;
        selheighttg = 4;
      }
    }

    if (value === "manage") {
      purchaseTargetFlag = true;
      manageBroadcastFlag = false;
    }

    if (value === "broadcastTest") {
      this.setState({
        emailTargetType: e.target.value,
        pricing: null,
        serviceId: 0,
        pricingDoneFlag: true,
        priceErrMsg: "",
        priceErrFlag: false,
        errMsg: "",
        errFlag: false,
        totalFee: 0,
        mktDiscount: 0,
        showPurchaseBoxFlag,
        // showGroupBoxFlag,
        showCustomerBoxFlag,
        // showUploadBoxFlag,
        showTestList,
        couponCode: "nocoupon",
        loadingFlag: false,
        broadcastWaitMsg: "",
        selheighttg,
      });
    } else {
      if (allok) {
        // console.log("in here 551 ...");
        this.setState({
          emailTargetType: e.target.value,
          showPurchaseBoxFlag,
          // showGroupBoxFlag,
          showCustomerBoxFlag,
          // showUploadBoxFlag,
          showTestList,
          showPriviousTargetList,
          pricingDoneFlag: false,
          selectedCoupon: null,
          pricing: null,
          selectedGroup: null,
          priceErrMsg: "",
          priceErrFlag: false,
          targetName,
          couponType: "nocoupon",
          errMsg: "",
          errFlag: false,
          selectedItem: null,
          loadingFlag: false,
          broadcastWaitMsg: "",
          purchaseTargetFlag,
          manageBroadcastFlag,
        });
      } else {
        this.setState({
          errMsg: msg,
          errFlag: true,
        });
      }
    }
  };

  returnFromPurchaseTarget = () => {
    this.setState({
      purchaseTargetFlag: false,
      manageBroadcastFlag: true,
      emailTargetType: "customer",
      showCustomerBoxFlag: true,
    });
  };

  handleCouponType = (e) => {
    let value = e.target.value;
    this.setState({
      couponType: value,
      selectedCoupon: null,
      selectedItem: null,
      couponCode: "",
      couponNote: "",
      validTillDate: new Date(),
    });
  };

  handleTargetListType = (e) => {
    this.setState({
      targetListType: e.target.value,
      pricing: null,
      pricingDoneFlag: false,
      errMsg: "",
      errFlag: false,
      priceErrFlag: false,
      priceErrMsg: "",
    });
  };

  onfocus = () => {
    let selht = 7;
    if (this.state.groupNames.length < 4)
      selht = this.state.groupNames.length + 2;
    this.setState({
      selheight: selht,
    });
    // this.setState({
    //   selheight: 3,
    // });
  };

  onblur = () => {
    this.setState({
      selheight: 1,
    });
  };

  handleSelectGroupName = () => {
    let value = document.getElementById("brdpub-grp-mgmt-new").value;
    let data = JSON.parse(value);
    // console.log("data:", data);
    this.setState({
      targetName: data.groupName,
      selectedGroup: data,
      priceErrMsg: "",
      priceErrFlag: false,
      pricingDoneFlag: false,
      selheight: 1,
    });
  };

  onfocustg = () => {
    let selht = 7;
    if (this.state.groupNames.length < 4)
      selht = this.state.groupNames.length + 2;
    this.setState({
      selheighttg: selht,
    });
  };

  onblurtg = () => {
    this.setState({
      selheighttg: 1,
    });
  };

  handleSelectTarget = () => {
    let value = document.getElementById("brdpub-existing-target-new").value;
    let data = JSON.parse(value);
    // console.log("data:", data);
    this.setState({
      targetName: data.targetListName,
      selheighttg: 1,
    });
  };

  onfocusSWCoupon = () => {
    let selht = 4;
    if (this.state.coupons.length < 2) selht = this.state.coupons.length + 2;
    this.setState({
      selheightCoup: selht,
    });
  };

  onblurSWCoupon = () => {
    this.setState({
      selheightCoup: 1,
    });
  };

  handleSWCouponName = () => {
    let value = document.getElementById("sw-brd-coupon").value;
    let data = JSON.parse(value);
    // console.log("data:", data);
    let note =
      "Coupon (" +
      data.couponName +
      ") gives " +
      data.percentDiscount +
      "% discount from " +
      data.fromDate.substring(0, 10) +
      " to " +
      data.toDate.substring(0, 10) +
      ".";
    this.setState({
      selectedCoupon: data,
      couponCode: data.couponName,
      couponNote: note,
      selheightCoup: 1,
    });
  };

  onFilesAdded = async (evt) => {
    // console.log("onFilesAdded...");
    const file = evt.target.files[0];

    // console.log("Buffer evt.target.files[0]:", file);

    let reader = new FileReader();

    reader.readAsText(file);

    reader.onload = () => this.storeResults(reader.result);
  };

  storeResults = async (result) => {
    let cat = result;
    // console.log("cat:", cat);
    let output = cat.split("\r\n");
    // console.log("output:", output);
    let out = [];
    output.forEach((cc) => {
      // console.log("cc:", cc);
      let zz = cc.split(",");
      if (zz.length > 0) out.push(cc);
    });

    // eliminate blank lines
    let finalOut = [];
    out.forEach((fo) => {
      if (fo !== "") finalOut.push(fo);
    });

    this.setState({
      loadedFile: finalOut,
    });
  };

  checkIfCampaignFooterSet = async () => {
    let param = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityFooter + param;
    // console.log("url:", url);
    try {
      let chkif = await axios.get(url);
      // console.log("chkif.data:", chkif.data);
      if (chkif.data.status === "error") {
        return { status: "error", Msg: chkif.data.Msg };
      } else return { status: "success", Msg: "" };
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  validateInput = async () => {
    let msg = "";
    let state = "success";
 
    if (this.state.targetName === "") {
      msg = "Unique Target Name is a must. ";
      state = "error";
    } else if (this.state.targetName.toLowerCase() === "inquiryRrference") {
      msg = "inquiryReference is reserved and cannot be used as targetName. ";
      state = "error";
    }
    if (this.state.couponType === "itemcoupon") {
      if (this.state.itemId === "" || this.state.couponCode === "") {
        if (msg === "") {
          msg = "Item Id and coupon name is a must for type Item Coupon.";
        } else {
          msg = "| Item Id and coupon name is a must for type Item Coupon.";
        }
        state = "error";
      }
    }

    if (
      this.state.emailTargetType !== "customer" &&
      this.state.targetName === "customer"
    ) {
      msg = "customer is reserved and cannot be used as targetName. ";
      state = "error";
    }

    if (
      this.state.emailTargetType !== "existinglist" &&
      this.state.emailTargetType !== "customer" &&
      this.state.emailTargetType !== "group"
    ) {
      try {
        let params =
          "communityId=" +
          this.props.communityId +
          "&targetListName=" +
          this.state.targetName +
          "&broadcastId=" +
          this.props.theBroadcast.broadcastId;
        let url = baandaServer + checkIfTargetListExists + params;
        // console.log("target url:", url);
        let tle = await axios.get(url);
        // console.log("tle.data:", tle.data);
        if (tle.data.status === "error") {
          state = "error";
          if (msg === "") {
            msg = tle.data.Msg;
          } else {
            msg = " " + tle.data.Msg;
          }
        }
      } catch (err) {
        state = "error";
        if (msg === "") {
          msg = err.message;
        } else {
          msg = "| " + err.message;
        }
      }
    }

    // Test for unique campaign name for the broadcast
    if (this.state.campaignName === "") {
      state = "error";
      if (msg === "") {
        msg = "Must have a unique campaign name.";
      } else {
        msg = "| Must have a unique campaign name.";
      }
    } else {
      try {
        let params =
          "communityId=" +
          this.props.communityId +
          "&campaignName=" +
          this.state.campaignName +
          "&broadcastId=" +
          this.props.theBroadcast.broadcastId;
        let url = baandaServer + checkIfCampaignNameExist + params;
        // console.log("campaign url:", url);
        let cname = await axios.get(url);
        // console.log("cname.data:", cname.data);
        if (cname.data.status === "error") {
          state = "error";
          if (msg === "") {
            msg = cname.data.Msg;
          } else {
            msg = "| " + cname.data.Msg;
          }
        }
      } catch (err) {
        state = "error";
        if (msg === "") {
          msg = err.message;
        } else {
          msg = "| " + err.message;
        }
      }
    }

    if (this.state.emailTargetType === "upload") {
      if (this.state.loadedFile && this.state.loadedFile.length <= 1) {
        state = "error";
        if (msg === "") {
          msg = "Must load a file to broadcast for Upload.";
        } else {
          msg = "| Must load a file to broadcast for Upload.";
        }
      } else {
        let headr = this.state.loadedFile[0].split(",");

        let isname = false;
        let isemail = false;
        let isphone = false;
        headr.forEach((hd) => {
          // console.log("hd:", hd);
          if (hd === "name") isname = true;
          if (hd === "email") isemail = true;
          if (hd === "cell" || hd === "phone") isphone = true;
        });

        if (isemail && isname && isphone) {
          // console.log("no action");
        } else {
          state = "error";
          if (msg === "") {
            msg = "Loaded file header row must have name, email, cell.";
          } else {
            msg = "| Loaded file header row must have name, email, cell.";
          }
        }
      }
    }

    // console.log('>>this.state.couponType: ', this.state.couponType);
    if (this.state.couponType === "baandarent") {
      // console.log('in here ... 783')
      let cpd = await this.getBaandaAdmCouponPercent();
      // console.log('cpd:', cpd);
      if (cpd.status === "error") {
        state = "error";
        if (msg === "") {
          msg = cpd.Msg;
        } else {
          msg = "| " + cpd.Msg;
        }
      }
    }

    return { status: state, Msg: msg };
  };

  getBaandaAdmCouponPercent = async () => {
    let url = baandaServer + getBaandaDna;
    // console.log('url:', url);
    try {
      let banret = await axios.get(url);
      // console.log('banret.data:', banret.data);
      if (banret.data.status === "success") {
        let percent = 0;
        let dis = banret.data.Msg;
        let couponExists = false;
        if (dis.discounts.length > 0) {
          dis.discounts.forEach((dd) => {
            if (dd.couponName === this.state.couponCode) {
              percent = parseFloat(dd.monthlyPercent);
              couponExists = true;
            }
          });
          if (!couponExists)
            return { status: "error", Msg: "Invalid coupon code entered" };
          else {
            return { status: "success", Msg: percent };
          }
        } else {
          return { status: "error", Msg: "No Baanda rental discount set." };
        }
      } else {
        return { status: "error", Msg: banret.data.Msg };
      }
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  getBroadcastFromDB = async () => {
    let params = "broadcastId=" + this.props.theBroadcast.broadcastId;
    let url = baandaServer + getABroadcast + params;
    try {
      let bret = await axios.get(url);
      if (bret.data.status === "success") {
        await this.setState({
          theBroadcast: bret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: bret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  createInputForFinalStep = async () => {
    let val = { status: "success", Msg: "" };
    if (this.state.emailTargetType !== "broadcastTest") {
      val = await this.validateInput();
    }
    // console.log("val:", val);
    let input = null;
    let state = "success";

    if (val.status === "success") {
      let totalFee = parseFloat(this.state.totalFee);
      if (
        parseFloat(this.props.communityId) === parseFloat(baandaCommunityId)
      ) {
        totalFee = 0;
      }

      let startDate, endDate;
      if (this.state.couponType === "itemcoupon" && this.state.selectedItem) {
        startDate = this.state.selectedItem.startDate;
        endDate = this.state.selectedItem.endDate;
      }
      if (this.state.couponType === "storewide" && this.state.selectedCoupon) {
        startDate = this.state.selectedCoupon.fromDate;
        endDate = this.state.selectedCoupon.toDate;
      }
      if (this.state.couponType === "baandarent" && this.state.validTillDate) {
        startDate = new Date();
        endDate = new Date(this.state.validTillDate);
      }

      let selectedCoupon = this.state.selectedCoupon;
      if (this.state.couponType === "baandarent") {
        let cpd = await this.getBaandaAdmCouponPercent();
        if (cpd.status === "success") {
          selectedCoupon = {
            couponName: this.state.couponCode,
            fromDate: startDate,
            toDate: endDate,
            percentDiscount: cpd.Msg,
            itemName: "Banda Adm. special discount.",
          };
        }
      }
      if (this.state.couponType === "itemcoupon") {
        selectedCoupon = {
          couponName: this.state.selectedItem.couponName,
          fromDate: startDate,
          toDate: endDate,
          percentDiscount: this.state.selectedItem.percent,
          itemName: this.state.selectedItem.itemName,
        };
      }

      let couponCode = "";
      if (this.state.selectedItem && this.state.selectedItem.coupon) {
        couponCode = this.state.selectedItem.coupon.code;
      }
      if (this.state.couponType === "storewide" && this.state.selectedCoupon) {
        couponCode = this.state.couponCode;
      }
      // console.log("this.state.couponType:", this.state.couponType);
      if (this.state.couponType === "baandarent") {
        couponCode = this.state.couponCode;
      }

      let baandaCell = "";
      if (this.props.auth.user.cell) {
        baandaCell = this.props.auth.user.cell.number;
      }

      let itemId = 0;
      // console.log("this.state.selectedItem:", this.state.selectedItem);
      // console.log("");
      if (this.state.selectedItem) {
        itemId = this.state.selectedItem.itemId;
      }

      await this.getBroadcastFromDB();

      input = {
        forBaandaStore: this.state.forBaandaStore,
        nonBaandaWebSite: this.state.nonBaandaWebSite,
        emailTargetType: this.state.emailTargetType,
        selectedCoupon,
        totalFee,
        targetName: this.state.targetName,
        campaignName: this.state.campaignName,
        selectedGroup: this.state.selectedGroup,
        clientProgram: "PublishBroadcast",
        clientFunction: "handleBaandaBroadcast",
        theBroadcast: this.state.theBroadcast,
        couponType: this.state.couponType,
        pricing: this.state.pricing,
        baandaCommunity: this.state.baandaCommunity,
        StripeAccId: this.state.baandaCommunity.stripe.id,
        communityId: this.props.communityId,
        broadcastDate: new Date(),
        loadedFile: this.state.loadedFile,
        couponNote: this.state.couponNote,
        itemId,
        // couponCode: this.state.couponCode,
        couponCode,
        baandaId: this.props.auth.user.baandaId,
        baandaemail: this.props.auth.user.email,
        baandaname: this.props.auth.user.name,
        baandaCell: this.props.auth.user.cell.number,
        publishingPrice: totalFee,
        baandaCommunityId: baandaCommunityId,
        commName: this.props.communityName,
        serviceId: this.state.serviceId,
        paymentAmount: totalFee,
        comFinMMDD: this.state.community.finMMDD,
        comFinYYYY: this.state.community.finYYYY,
        couponStartDate: startDate,
        couponEndDate: endDate,
        sender: {
          email: this.props.auth.user.email,
          name: this.props.auth.user.name,
          cell: baandaCell,
        },
      };
    } else {
      state = "error";
      input = val.Msg;
    }

    return { status: state, Msg: input };
  };

  reInitialize = () => {
    this.setState({
      emailTargetType: "customer",
      groupNames: [],
      searchGroupName: "",
      selectedGroup: null,

      showPurchaseBoxFlag: false,
      // showGroupBoxFlag: false,
      showCustomerBoxFlag: true,
      // showUploadBoxFlag: false,
      showCouponFlag: false,

      manageBroadcastFlag: true,
      finalBroadcastFlag: false,

      targetListType: "new",
      selheight: 1,
      selheighttg: 1,
      selheightCoup: 1,

      couponType: "nocoupon",
      couponCode: "",
      couponNote: "",
      targetName: "",
      campaignName: "",
      coupons: [],
      selectedCoupon: null,
      selectedTargetList: [],

      loadedFile: [],
      itemId: 0,

      targetGeneratedFlag: false,

      pricingDoneFlag: false,
      totalFee: 0,
      input: null,

      errMsg: "",
      errFlag: false,
      priceErrMsg: "",
      priceErrFlag: false,

      // baandaCommunity: null,
      paymentAmount: 0,
      loadingFlag: false,
      mktDiscount: 0,
      broadcastWaitMsg: "",
      itemSelectionFlag: false,
      selectedItem: null,
    });
  };

  handleBroadcast = async () => {
    this.setState({
      loadingFlag: true,
      broadcastWaitMsg: " Please wait ... may take up to few minutes...",
    });
    let inp = await this.createInputForFinalStep();
    // console.log("inp:", inp);
    if (inp.status === "success") {
      if (
        parseFloat(this.props.communityId) !== parseFloat(baandaCommunityId)
      ) {
        if (this.state.emailTargetType !== "broadcastTest") {
          let paymentAmount = parseFloat(this.state.totalFee);
          this.setState({
            manageBroadcastFlag: false,
            finalBroadcastFlag: true,
            errMsg: "Broadcast Successful",
            errFlag: false,
            paymentAmount,
            input: inp.Msg,
          });
        } else {
          let brdret = await this.handleBaandaBroadcast(inp.Msg);
          console.log("brdret.data:", brdret.data);
          if (brdret.status === "success") {
            this.reInitialize();
            this.setState({
              errMsg: "Broadcast Successful",
              errFlag: false,
              targetName: "",
              emailTargetType: "customer",
              loadingFlag: false,
              broadcastWaitMsg: "",
              showPriviousTargetList: false,
            });
          } else {
            this.setState({
              errMsg: brdret.Msg,
              errFlag: true,
              loadingFlag: false,
              broadcastWaitMsg: "",
            });
          }
        }
      } else {
        let brdret = await this.handleBaandaBroadcast(inp.Msg);
        console.log("brdret:", brdret);
        if (brdret.status === "success") {
          this.reInitialize();
          // await this.GetBaandaCommunityInfo();
          this.setState({
            errMsg: "Broadcast Successful.",
            errFlag: false,
            targetName: "",
            emailTargetType: "customer",
            loadingFlag: false,
            broadcastWaitMsg: "",
            showPriviousTargetList: false,
          });
        } else {
          this.setState({
            errMsg: brdret.Msg,
            errFlag: true,
            loadingFlag: false,
            broadcastWaitMsg: "",
          });
        }
      }
    } else {
      this.setState({
        errMsg: inp.Msg,
        errFlag: true,
        loadingFlag: false,
        broadcastWaitMsg: "",
      });
    }
  };

  handleBaandaBroadcast = async (inp) => {
    let url = baandaServer + broadcastACampign;
    // console.log("url:", url, " inp:", inp);
    try {
      let dispret = await axios.post(url, inp);
      // console.log("dispret.data:", dispret.data);
      if (dispret.data.status === "success") {
        return { status: "success", Msg: "" };
      } else {
        return { status: "error", Msg: dispret.data.Msg };
      }
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  handledFinalBroadcastExit = async () => {
    this.setState({
      manageBroadcastFlag: true,
      finalBroadcastFlag: false,
      emailTargetType: "customer",
      targetName: "customer",
      pricingDoneFlag: false,
      couponType: "nocoupon",
      selectedItem: null,
      couponNote: "",
      couponCode: "",
      validTillDate: null,
      priceErrMsg: "",
      showPriviousTargetList: false,
    });
  };

  handleItemSelection = () => {
    this.setState({
      itemSelectionFlag: true,
    });
  };

  returnFromItemSelection = () => {
    this.setState({
      itemSelectionFlag: false,
    });
  };

  goSearchItem = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&itemName=" +
      this.state.itemName;
    let url = baandaServer + getCouponItems + params;
    // console.log("url:", url);
    try {
      let itemret = await axios.get(url);
      // console.log("itemret.data:", itemret.data);
      if (itemret.data.status === "success") {
        if (itemret.data.Msg.length > 0) {
          this.setState({
            itemErrMsg: "",
            itemErrFlag: false,
            items: itemret.data.Msg,
          });
        } else {
          this.setState({
            itemErrMsg: "No active coupon for the search.",
            itemErrFlag: true,
            items: [],
          });
        }
      } else {
        this.setState({
          itemErrMsg: itemret.data.Msg,
          itemErrFlag: true,
          items: [],
        });
      }
    } catch (err) {
      this.setState({
        itemErrMsg: err.message,
        itemErrFlag: true,
      });
    }
  };

  selectThisItemCoupon = (obj) => {
    // console.log("obj:", obj);
    let note =
      "Coupon (" +
      obj.couponName +
      ") of the item (" +
      obj.itemName +
      ") use code (" +
      obj.coupon.code +
      "), gives " +
      obj.percent +
      "% discount from " +
      obj.coupon.startDate.substring(0, 10) +
      " to " +
      obj.coupon.endDate.substring(0, 10) +
      ".";
    this.setState({
      selectedItem: obj,
      couponCode: obj.couponName,
      couponNote: note,
    });
    this.returnFromItemSelection();
  };

  handleValidtillDate = async (date) => {
    this.setState({
      validTillDate: date,
    });
  };

  handleForBaandaStore = async () => {
    await this.setState((prevstate) => ({
      forBaandaStore: !prevstate.forBaandaStore,
    }));
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("PublishBroadcast...");

    // Upload Spinner _____________________________________________
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <ReactLoading
          type={"spokes"}
          color={"#237547"} 
          height={25}
          width={25}
          className="comm-entry-spinner"
        />
      );
    } else {
      uploadingSpin = null;
    }
    // Upload Panel _______________________________________________

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    // let priceGrpButton = (
    //   <button
    //     className="btn_reg_70"
    //     type="button"
    //     onClick={this.handlePricing}
    //     style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //   >
    //     Price
    //   </button>
    // );

    let getCouponButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleCouponFetch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Fetch
      </button>
    );

    let opsButtonPanel;
    opsButtonPanel = (
      <div>
        {this.state.emailTargetType !== "broadcastTest" ? (
          <div>
            <div className="row brd-pub-pos">
              <div className="col-2 text-right coupon-code-lblx">Target</div>
              {/* {this.state.emailTargetType === "existinglist" ||
              this.state.emailTargetType === "customer" ||
              this.state.emailTargetType === "group" ? (
                <div className="col-4 text-left">
                  <font color="blue" size="3">
                    {this.state.targetName}
                  </font>
                </div>
              ) : (
                <div className="col-4 text-left">
                  <input
                    name="targetName"
                    type="text"
                    value={this.state.targetName}
                    onChange={this.onChange}
                    size="20"
                    maxLength="20"
                    className="coupon-code"
                    placeholder="Unique target name."
                  />
                </div>
              )} */}

              <div className="col-4 text-left">
                <font color="blue" size="3">
                  {this.state.targetName}
                </font>
              </div>

              <div className="col-2 text-right coupon-code-lblx">
                Campaign Name
              </div>
              <div className="col-4 text-left">
                <input
                  name="campaignName"
                  type="text"
                  value={this.state.campaignName}
                  onChange={this.onChange}
                  size="20"
                  maxLength="20"
                  className="pub-campaign-name"
                  placeholder="Unique campaign name."
                />
              </div>
            </div>
          </div>
        ) : null}

        {(this.state.couponType === "nocoupon" ||
        (this.state.couponType === "storewide" && this.state.selectedCoupon) ||
        (this.state.couponType === "itemcoupon" && this.state.selectedItem) ||
        (this.state.couponType === "baandarent" &&
          this.state.couponCode !== "") || !this.state.forBaandaStore) ? (
          <div className="brd-pub-pos-btn ">
            <button
              className="btn_reg_60"
              type="button"
              onClick={this.handleBroadcast}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Send
            </button>{" "}
            &nbsp;
            {parseFloat(this.props.communityId) ===
            parseFloat(baandaCommunityId) ? (
              <div className="row">
                <div className="col-6 text-right">
                  {this.state.broadcastWaitMsg}
                </div>
                <div className="col-6 text-left">{uploadingSpin} </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : null}
      </div>
    );

    let targetListSelectPanel;
    targetListSelectPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Target Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="customer"
                checked={this.state.emailTargetType === "customer"}
                onChange={this.handleEmailTargetType}
              />{" "}
              Customers
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="manage"
                checked={this.state.emailTargetType === "manage"}
                onChange={this.handleEmailTargetType}
              />{" "}
              Manage
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="existinglist"
                checked={this.state.emailTargetType === "existinglist"}
                onChange={this.handleEmailTargetType}
              />{" "}
              Existing List
            </label>
          </div>
          {/* <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="group"
                checked={this.state.emailTargetType === "group"}
                onChange={this.handleEmailTargetType}
              />{" "}
              Group
            </label>
          </div> */}
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="broadcastTest"
                checked={this.state.emailTargetType === "broadcastTest"}
                onChange={this.handleEmailTargetType}
              />{" "}
              Test
            </label>
          </div>
        </div>
      </div>
    );

    let couponTypePanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Coupon Type to attach&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="nocoupon"
                checked={this.state.couponType === "nocoupon"}
                onChange={this.handleCouponType}
              />{" "}
              No Coupon
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="storewide"
                checked={this.state.couponType === "storewide"}
                onChange={this.handleCouponType}
              />{" "}
              Store Wide
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="itemcoupon"
                checked={this.state.couponType === "itemcoupon"}
                onChange={this.handleCouponType}
              />{" "}
              Item Coupon
            </label>
          </div>
        </div>
      </div>
    );

    let couponDisplayPanel;
    if (this.state.showCouponFlag) {
      if (this.state.couponType === "storewide") {
        if (this.state.coupons.length > 0) {
          let swCouponList = this.state.coupons.map((obj, i) => {
            return (
              <option key={i} value={JSON.stringify(obj)}>
                {obj.couponName}&nbsp;
              </option>
            );
          });

          couponDisplayPanel = (
            <select
              size={this.state.selheightCoup}
              id="sw-brd-coupon"
              name="sw-brd-coupon"
              onChange={this.handleSWCouponName}
              className="brd-sw-coupon-list"
              onFocus={this.onfocusSWCoupon}
              onBlur={this.onblurSWCoupon}
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select a Coupon
              </option>

              {swCouponList}
            </select>
          );
        } else {
          couponDisplayPanel = <font color="red">No valid coupons defined.</font>
        }
      }
    }

    let couponInputPanel;
    if (
      this.state.couponType !== "nocoupon" &&
      this.state.couponType !== "itemcoupon" &&
      this.state.couponType !== "baandarent"
    ) {
      couponInputPanel = (
        <div>
          <div className="row coupon-code-row">
            <div className="col-3 text-right coupon-code-lblx">Coupon Code</div>
            <div className="col-3 text-left">{getCouponButton}</div>
            <div className="col-6 text-left">{couponDisplayPanel}</div>
          </div>
          <div className="row coupon-code-row">
            <div className="col-3 text-right coupon-code-lblx">Coupon Note</div>
            <div className="col-9 text-left">
              <textarea
                name="couponNote"
                maxLength="250"
                placeholder="A note to accompany the code in email in less than 250 characters."
                rows="2"
                wrap="hard"
                spellCheck="true"
                className="brd-note-textarea"
                onChange={this.onChange}
                value={this.state.couponNote}
                required
              />
            </div>
          </div>
          {this.state.selectedCoupon ? (
            <div className="text-center brd-storewide-coupon">
              Coupon ({this.state.selectedCoupon.couponName}) gives{" "}
              {this.state.selectedCoupon.percentDiscount}% discount from{" "}
              {this.state.selectedCoupon.fromDate.substring(0, 10)} to{" "}
              {this.state.selectedCoupon.toDate.substring(0, 10)}
            </div>
          ) : null}
        </div>
      );
    }

    let itemsListPanel;
    if (this.state.items.length > 0) {
      let bool = true;
      itemsListPanel = (
        <div>
          <div className="row brd-items-list-row">
            <div className="col-2 text-right brd-item-header-lbl">Ops</div>
            <div className="col-10 text-center brd-item-header-lbl">
              Item Name | Coupon Name | Coupon Code | percent
            </div>
          </div>
          <div className="fixedsize-brd-items-list">
            {this.state.items.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool
                        ? "row row-col-brd-item-dr"
                        : "row row-col-brd-item-lt"
                    }
                  >
                    <div className="col-2 text-center">
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.selectThisItemCoupon(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fas fa-check" />
                      </button>
                    </div>
                    <div className="col-10 text-left item-category-label">
                      {obj.itemName} | {obj.couponName} | {obj.coupon.code} |{" "}
                      {obj.percent}%
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let selectItemPanel;
    if (this.state.itemSelectionFlag) {
      selectItemPanel = (
        <div className="pub-brd-item-sel-box">
          <div className="text-center brd-pub-item-headr">
            Select Item &nbsp;
            <button
              className="btn_back_main"
              type="button"
              onClick={this.returnFromItemSelection}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div className="row">
            <div className="col-3 text-right">Name</div>
            <div className="col-9 text-left">
              <input
                name="itemName"
                type="text"
                value={this.state.itemName}
                onChange={this.onChange}
                size="60"
                maxLength="100"
                className="brd-pub-item-name"
                placeholder=""
              />{" "}
              &nbsp;
              <button
                className="btn_searh_main"
                type="button"
                onClick={this.goSearchItem}
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-search-plus" />
              </button>
            </div>
          </div>
          {itemsListPanel}
          <div
            className={
              this.state.itemErrFlag
                ? "text-center brd-disc-item-msg-err"
                : "text-center brd-disc-item-msg"
            }
          >
            {this.state.itemErrMsg}
          </div>
        </div>
      );
    }

    if (this.state.couponType === "itemcoupon") {
      couponInputPanel = (
        <div>
          <div className="row coupon-code-row">
            <div className="col-3 text-right coupon-code-lblx">Coupon Code</div>
            <div className="col-9 text-left brd-item-coupon-lbl">
              <button
                className="btn_reg_70"
                type="button"
                onClick={this.handleItemSelection}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Item
              </button>{" "}
              &nbsp; Select an item / coupon.
            </div>
          </div>
          {selectItemPanel}
          <div className="row coupon-code-row">
            <div className="col-3 text-right coupon-code-lblx">Coupon Note</div>
            <div className="col-9 text-left">
              <textarea
                name="couponNote"
                maxLength="250"
                placeholder="A note to accompany the code in email in less than 250 characters."
                rows="2"
                wrap="hard"
                spellCheck="true"
                className="brd-note-textarea"
                onChange={this.onChange}
                value={this.state.couponNote}
                required
              />
            </div>
          </div>
          {this.state.selectedItem ? (
            <div className="text-center brd-storewide-coupon">
              Coupon ({this.state.selectedItem.couponName}) of the item (
              {this.state.selectedItem.itemName}), use code (
              {this.state.selectedItem.coupon.code}), gives{" "}
              {this.state.selectedItem.percent}% discount from{" "}
              {this.state.selectedItem.startDate.substring(0, 10)} to{" "}
              {this.state.selectedItem.endDate.substring(0, 10)}
            </div>
          ) : null}
        </div>
      );
    }

    if (this.state.couponType === "baandarent") {
      couponInputPanel = (
        <div>
          <div className="row coupon-code-row">
            <div className="col-3 text-right coupon-code-lblx">Coupon Code</div>
            <div className="col-4 text-left brd-item-coupon-lbl">
              <input
                name="couponCode"
                type="text"
                value={this.state.couponCode}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="coupon-code-baanda"
                placeholder="Admin ... manually verify this code."
              />{" "}
              &nbsp;
            </div>
            <div className="col-1 text-right brd-item-coupon-lbl">Ends</div>
            <div className="col-4 text-left brd-item-coupon-lbl">
              <DatePicker
                selected={this.state.validTillDate}
                onSelect={this.handleValidtillDate}
                onChange={this.handleValidtillDate}
                minDate={this.state.validTillDate}
                name="validTillDate"
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
          {selectItemPanel}
          <div className="row coupon-code-row">
            <div className="col-3 text-right coupon-code-lblx">Coupon Note</div>
            <div className="col-9 text-left">
              <textarea
                name="couponNote"
                maxLength="250"
                placeholder="A note to accompany the code in email in less than 250 characters."
                rows="2"
                wrap="hard"
                spellCheck="true"
                className="brd-note-textarea"
                onChange={this.onChange}
                value={this.state.couponNote}
                required
              />
            </div>
          </div>
        </div>
      );
    }

    let groupBoxPanel;
    if (this.state.showCustomerBoxFlag) {
      let mktcoupFlag = false;
      let mktDiscount = 0;
      if (this.state.pricing && this.state.pricing.mktcoupons) {
        let today = new Date().getTime();
        let todate = new Date(this.state.pricing.mktcoupons.toDate).getTime();
        let fromDate = new Date(
          this.state.pricing.mktcoupons.fromDate
        ).getTime();
        if (today <= todate && today >= fromDate) {
          mktcoupFlag = true;
          mktDiscount = parseFloat(
            this.state.pricing.mktcoupons.percentDiscount
          );
        }
      }
      groupBoxPanel = (
        <div className="brdpub-group-box">
          <div className="text-center target-customer-lbl">
            Email will be sent to all existing customers.
          </div>
          <div className="text-center">
            {
              <button
                className="btn_reg_70"
                type="button"
                onClick={this.handlePricing}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Price
              </button>
            }
          </div>
          <div
            className={
              this.state.priceErrFlag
                ? "text-center price-quote-msg-err"
                : "price-quote-msg"
            }
          >
            {this.state.priceErrMsg}
          </div>
          {mktcoupFlag &&
          parseFloat(this.props.communityId) !==
            parseFloat(baandaCommunityId) ? (
            <div>
              You are receiving {this.commaFormattedCurrency(mktDiscount)} %
              special discount.
            </div>
          ) : null}
        </div>
      );
    }

    if (this.state.showPriviousTargetList) {
      let selTargetList = this.state.selectedTargetList.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.targetListName}&nbsp;
          </option>
        );
      });
      groupBoxPanel = (
        // <div className="brdpub-group-box ">
        <div>
          <div className="text-center target-customer-lbl-grp">
            Select a Target Name
          </div>
          <select
            size={this.state.selheighttg}
            id="brdpub-existing-target-new"
            name="brdpub-existing-target-new"
            onChange={this.handleSelectTarget}
            className="brd-grp-list group-dd-pos"
            onFocus={this.onfocustg}
            onBlur={this.onblurtg}
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Select a Target Name
            </option>
            {selTargetList}&nbsp;
          </select>
          {this.state.targetName !== "" ? (
            <div className="brdpub-grp-select-btn-pos">
              <button
                className="btn_reg_70"
                type="button"
                onClick={this.handlePricing}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Price
              </button>
            </div>
          ) : null}
          <div
            className={
              this.state.priceErrFlag
                ? "text-center price-quote-msg-err"
                : "price-quote-msg"
            }
          >
            {this.state.priceErrMsg}
          </div>
        </div>
      );
    }

    if (this.state.showPriviousTargetList) {
      groupBoxPanel = (
        <div className="brdpub-group-box">
          {/* <div className="text-center target-customer-lbl">
            Use an Existing Target List
          </div> */}
          <div className="text-center">{groupBoxPanel}</div>
        </div>
      );
    }

    if (this.state.showTestList) {
      groupBoxPanel = (
        <div className="brdpub-group-box">
          <div className="text-center target-customer-lbl">
            A test email will be sent to you.
          </div>
        </div>
      );
    }

    let targetStoreTypePanel;
    let couponFinalPanel;
    if (this.state.pricingDoneFlag) {
      targetStoreTypePanel = (
        <div className="text-center target-store-type-panel">
          <Checkbox
            checked={this.state.forBaandaStore}
            onChange={this.handleForBaandaStore}
          />{" "}
          {this.state.forBaandaStore
            ? "Campaigning for a Baanda Community"
            : "Campaigning for a non-Baanda community"}
        </div>
      );
      if (this.state.forBaandaStore) {
        couponFinalPanel = (
          <div>
            {couponTypePanel}
            {couponInputPanel}
            {opsButtonPanel}
          </div>
        );
      } else {
        couponFinalPanel = (
          <div>
            <div className="row brd_website_url">
              <div className="col-4 brd_website_url_label text-right">
                Web Site Url:
              </div>
              <div className="col-8  brd_website_url_input text-left">
                <input
                  name="nonBaandaWebSite"
                  type="text"
                  value={this.state.nonBaandaWebSite}
                  onChange={this.onChange}
                  size="100"
                  maxLength="100"
                  className="input_companyname"
                  placeholder="Website URL with protocol e.g. https://www.theURL.com (optional)"
                />
              </div>
            </div>
            {opsButtonPanel}
          </div>
        );
      }
    }

    let outputPanel;

    if (this.state.purchaseTargetFlag) {
      outputPanel = (
        <ManageTarget
          communityId={this.props.communityId}
          broadcastId={this.props.theBroadcast.broadcastId}
          returnToCaller={this.returnFromPurchaseTarget}
        />
      );
    }

    if (this.state.manageBroadcastFlag) {
      outputPanel = (
        <div>
          <div className="row">
            <div className="col-8 text-right brdpub-header">
              Publish & Broadcast&nbsp;&nbsp;{infoModalButton}
            </div>
            <div className="col-4 text-center brdpub-name-header">
              {this.props.theBroadcast.broadcastName}
            </div>
          </div>

          {targetListSelectPanel}
          {groupBoxPanel}
          {targetStoreTypePanel}
          {couponFinalPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center brd-publish-msgx-err"
                : "text-center brd-publish-msgx"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.finalBroadcastFlag) {
      console.log(">>> this.state.totalFee:", parseFloat(this.state.totalFee));
      outputPanel = (
        <div>
          <div className="row">
            <div className="col-8 text-right brdpub-header">
              Publish & Broadcast&nbsp;&nbsp;{infoModalButton}
            </div>
            <div className="col-4 text-center brdpub-name-header">
              {this.props.theBroadcast.broadcastName}
            </div>
          </div>
          <div className="text-center">
            <PayForCCPlatform
              inputData={this.state.input}
              paymentAmount={parseFloat(this.state.totalFee)}
              caller="broadcast"
              handleExit={this.handledFinalBroadcastExit}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

PublishBroadcast.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PublishBroadcast));
