import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactLoading from "react-loading";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./HandleShippingDetails.css";

import PayForCCPlatform from "../../../../../../account/finance/communities/PayForCCPlatform";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const savePackingDone = "/routes/dashboard/savePackingDone";
const getAnInvoice = "/routes/invoice/getAnInvoice?";
const getShippingRates = "/routes/easypost/getShippingRates"; // though the name is get... it is actually a post
const postUseCredits = "/routes/finance/postUseCredits";

const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const getServicePrice = "/routes/create/getServicePrice?";
const saveSelfSpecialShipping = "/routes/shipping/saveSelfSpecialShipping";
const getDefaultPkg = "/routes/shipping/getDefaultPkg?";
const closeShippingInvoice = "/routes/shipping/closeShippingInvoice";
const getCatalogPkgForDelivery = "/routes/dashboard/getCatalogPkgForDelivery?";

const usps_account = process.env.REACT_APP_USPS_ACCOUNT;
const ups_account = process.env.REACT_APP_UPS_ACCOUNT;
const dhl_account = process.env.REACT_APP_DHL_ACCOUNT;
// const fedex_account = process.env.REACT_APP_FEDEX_ACCOUNT;

const BaandaCommunityId = process.env.REACT_APP_BAANDA_COMMUNITY_ID;
const shippingServiceFee = process.env.REACT_APP_BUY_SHIPPING_SERVICE_FEE;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111010000";
const readMeCodeManualInfo = "0000000000";

class HandleShippingDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shippingHandlerType: "baanda",

      selectedCarrierAc: "",
      selectedCarrier: "",

      actionType: "packing",
      scaleType: "fps",

      packageWidth: 0,
      pkgLength: 0,
      packageHeight: 0,
      packageWeight: 1,
      packageWeightOzs: 0,

      pkgScaleType: "fps",

      splPkgWidth: 0,
      splPkgLength: 0,
      splPkgHeight: 0,
      splPkgWeight: 0,
      splPkgScaleType: "fps",

      shipDate: new Date(),

      packingConfirm: false,

      specialShipping: false,
      packingCategory: "regular",

      message: "",
      errFlag: false,

      fromAddress: null,
      fromAddressList: [],
      selheight: 1,
      selheightad: 1,
      selheightpkg: 1,

      invoice: null,

      newRates: [],

      loadingFlag: false,

      selectedRateMsg: "",
      selectedRateObj: null,
      selectedRate: 0,
      showSelectedRate: false,
      confirmRate: false,

      confirmDirectCCBuy: false,

      directShipmentBuyflag: false,
      insufficientMsg: "",

      showLabelFlag: false,
      buyResponse: null,
      accStatus: "",
      accMsg: "",

      labelurl: "",
      labelTrack: "",
      trackerurl: "",

      baandaCommunity: null,
      buyUsingCCFlag: false,
      csFee: null,
      input: null,
      shipmentPrice: 0.0,
      deviceSize: "",

      showActionType: true,
      showHandledBy: true,
      showSelectRates: true,
      showShippingRates: true,
      showCCBuy: true,

      selfCarrierName: "",
      selfTrackingurl: "",
      selfTrackingno: "",
      selfPostPrice: 0,
      selfPostNote: "",
      specialSelfMsg:
        "The entry is not system validated. Hence accuracy is manual. Double check for the saving is final.",
      totalNumberOfPkg: 1,
      thePkgNumber: 1,

      specialShippingOnlyFlag: false,

      defaultPkgs: [],
      selectedPackage: null,

      shippingDoneFlag: false,
      closeShipFlag: false,

      shipType: "regular",
      manualPkgType: "parcel",
      standardPkgList: [],

      selectedStandardPackage: "",

      showPlanPkgFlag: false,
      planPack: null,
      showPlanDtl: [],

      toPhone: "",
      manualCancel: false,
    };
  }

  componentDidMount = async () => {
    // console.log("Just in shipping details ...");
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    // console.log("before getting the invoice ...");
    await this.getThisInvoice();
    // console.log("compDidmount ... this.state.invoice:", this.state.invoice);
    let specialShipping = false;
    // if (this.props.invoice.spSpecialExists) specialShipping = true;
    let packageWidth = 0,
      pkgLength = 0,
      packageHeight = 0,
      packageWeight = 1,
      packageWeightOzs = 0,
      pkgScaleType = "fps";
    let splPkgWidth = 0,
      splPkgLength = 0,
      splPkgHeight = 0,
      splPkgWeight = 0,
      splPkgWeightOzs = 0,
      splPkgScaleType = "fps";

    let specialShippingOnlyFlag = false;
    let shippingHandlerType = "baanda";
    if (
      !this.props.invoice.spRegularExists &&
      this.props.invoice.spSpecialExists
    ) {
      specialShippingOnlyFlag = true;
      shippingHandlerType = "self";
    }

    let totalNumberOfPkg = 1;
    if (this.props.invoice.spInfo.numberOfRegularPackages)
      totalNumberOfPkg = this.props.invoice.spInfo.numberOfRegularPackages;

    let fromAddress = null;
    let fromAddressList = [];

    if (
      this.state.invoice &&
      this.state.invoice.invoiceOrigin === "manual-shipping" &&
      this.state.invoice.deliveryData
    ) {
      let daf = this.state.invoice.deliveryData.fromAddress;
      // console.log('daf:', daf);
      fromAddress = {
        address: {
          street1: daf.street1,
          street2: daf.street2,
          city: daf.city,
          state: daf.state,
          zip: daf.postalCode,
          country: daf.country,
          phone: daf.phone,
        },
        name: daf.shipFromName,
      };
      fromAddressList = [
        {
          address: {
            street1: daf.street1,
            street2: daf.street2,
            city: daf.city,
            state: daf.state,
            zip: daf.postalCode,
            country: daf.country,
          },
        },
      ];
      //   From: {fa.street1} {fa.street2 === "" ? null : ", " + fa.street2},{" "}
      //       {fa.city} {fa.state}, {fa.zip}
    } else {
      if (!this.props.store) {
        fromAddressList = this.props.shippingMeta.fromAddress;
        if (fromAddressList.length === 1)
          fromAddress = fromAddressList[0].address;
      } else {
        fromAddressList = this.props.store.shippingMeta.fromAddress;
        if (fromAddressList.length >= 1) {
          fromAddress = fromAddressList[0].address;
        } else {
          // The store has not defined their from address. Hence, using store address
          fromAddressList = [
            {
              address: {
                street1: this.props.store.displayAddress.street,
                street2: "",
                city: this.props.store.displayAddress.city,
                state: this.props.store.displayAddress.state,
                zip: this.props.store.displayAddress.postalCode,
                country: this.props.store.displayAddress.country,
              },
            },
          ];
          fromAddress = fromAddressList[0].address;
        }
      }
    }

    await this.getDefaultPackages();

    let thePkgNumber = 1;
    let msg = "";
    let errFlag = false;
    let noOfPkgsShipped = 0;
    if (this.state.invoice && this.state.invoice.spInfo) {
      let rp = this.state.invoice.spInfo.regularPkg;
      // console.log("rp.length:", rp.length);
      if (rp.length > 0) thePkgNumber = rp.length;

      rp.forEach((pkg) => {
        if (pkg.regularCarrier && pkg.regularCarrier.carrierName !== "") {
          noOfPkgsShipped++;
          thePkgNumber += 1;
        }
      });
      if (noOfPkgsShipped > 0) {
        if (noOfPkgsShipped === 1)
          msg = noOfPkgsShipped + " package has already been shipped.";
        else msg = noOfPkgsShipped + " packages have already been shipped.";
        errFlag = true;
        thePkgNumber = noOfPkgsShipped + 1;
      }
      if (rp.length > noOfPkgsShipped) {
        let pwOzs = 0;
        if (rp[noOfPkgsShipped].weightOzs) {
          pwOzs = rp[noOfPkgsShipped].weightOzs;
        }
        packageWidth = rp[noOfPkgsShipped].width;
        pkgLength = rp[noOfPkgsShipped].length;
        packageHeight = rp[noOfPkgsShipped].height;
        packageWeight = rp[noOfPkgsShipped].weight;
        packageWeightOzs = pwOzs;
        pkgScaleType = rp[noOfPkgsShipped].scaleType;
      }
    }

    if (this.state.invoice.periodicDeliveryObj) {
      if (this.state.invoice.periodicDeliveryObj.periodicShipData) {
        if (
          this.state.invoice.periodicDeliveryObj.periodicShipData.type ===
          "planned"
        ) {
          let pl =
            this.state.invoice.periodicDeliveryObj.periodicShipData
              .plannedPkgDimension;

          let pwOzs = 0;
          if (pl.weightOzs) pwOzs = pl.weightOzs;

          packageWidth = pl.width;
          pkgLength = pl.length;
          packageHeight = pl.height;
          packageWeight = pl.weight;
          packageWeightOzs = pwOzs;
          pkgScaleType = pl.scaleType;
        }
      }
    }

    let shipType = "regular";
    if (this.state.invoice.invoiceCategory === "manual-shipping") {
      shipType = "manual";
    }

    this.setState({
      specialShipping,
      packageWidth,
      pkgLength,
      packageHeight,
      packageWeight,
      packageWeightOzs,
      pkgScaleType,
      splPkgWidth,
      splPkgLength,
      splPkgHeight,
      splPkgWeight,
      splPkgWeightOzs,
      splPkgScaleType,
      fromAddress,
      fromAddressList,
      totalNumberOfPkg,
      specialShippingOnlyFlag,
      shippingHandlerType,
      deviceSize,
      thePkgNumber,
      message: msg,
      errFlag,
      shipType,
    });
  };

  getDefaultPackages = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "communityId=" + this.props.communityId + "&coopStoreId=" + coopStoreId;
    let url = baandaServer + getDefaultPkg + param;
    // console.log("url:", url);
    try {
      let pkgret = await axios.get(url);
      // console.log("pkgret.data:", pkgret.data);
      if (pkgret.data.status === "success") {
        this.setState({
          defaultPkgs: pkgret.data.Msg,
        });
      }
    } catch (err) {
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  getCommunityInfo = async (communityId) => {
    let parms = "communityId=" + parseFloat(communityId);
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("getCommunityInfo url:", url);
    let community = null;
    try {
      let retData = await axios.get(url);
      // console.log("SellCredit retData: ", retData);
      if (retData.data.status === "success") {
        community = retData.data.Msg;
      } else {
        await this.setState({
          message: retData.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }

    return community;
  };

  getServiceFee = async () => {
    try {
      let param = "serviceName=" + shippingServiceFee;

      let url = baandaServer + getServicePrice + param;
      // console.log("url:", url);
      let serPrice = await axios.get(url);
      // console.log("serPrice:", serPrice);
      if (serPrice.data.status === "success") {
        if (serPrice.data.Msg) {
          this.setState({
            csFee: serPrice.data.Msg,
          });
        } else {
          // console.log("Pricing has not been set");
          this.setState({
            message:
              "Baanda service price for shipping services has not been set. ",
            errFlag: true,
          });
        }
      } else {
        // console.log("Pricing has not been set");
        this.setState({
          message: serPrice.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getServiceFee Err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  getThisInvoice = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let param =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&invoiceId=" +
      this.props.invoice.invoiceId +
      "&role=communityAdmin";
    let url = baandaServer + getAnInvoice + param;
    // console.log("url:", url);
    try {
      let retinv = await axios.get(url);
      // console.log("retinv:", retinv);
      if (retinv.data.status === "success") {
        await this.setState({
          message: "",
          errFlag: false,
          invoice: retinv.data.Msg,
        });
      } else {
        await this.setState({
          message: retinv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("HandleShippingDetails getThisInvoice err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  getPlannedPackage = async (planItem) => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&itemId=" +
      planItem.itemId +
      "&year=" +
      planItem.year +
      "&month=" +
      planItem.month;
    let url = baandaServer + getCatalogPkgForDelivery + params;
    try {
      let pret = await axios.get(url);
      return pret.data;
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  showPlanPack = async (obj) => {
    // console.log("showPlanPack obj:", obj);
    let planItem = {
      itemId: obj.itemId,
      year: obj.plannedPack.year,
      month: obj.plannedPack.month,
    };
    let ppret = await this.getPlannedPackage(planItem);

    let showPlanDtl = [];
    if (ppret.status === "success") {
      obj.plannedPack.plannedRef.forEach((pln) => {
        let item = "";
        ppret.Msg.batch.forEach((lin) => {
          if (pln.batch === lin.batchNumber) {
            lin.lines.forEach((itm) => {
              item += ">> Item: " + itm.pkgItemName;
              if (itm.pkgSubItemName !== "") {
                item += " (Sub: " + itm.pkgSubItemName + ") ";
              }
              item +=
                "Qty:" + itm.qtyOfThisInPack + " (id=" + itm.pkgItemId + "); ";
            });
            showPlanDtl.push(item);
          }
        });
      });
    } else {
    }
    this.setState({
      showPlanPkgFlag: true,
      showPlanDtl,
    });
  };

  returnFromPlanPkg = () => {
    this.setState({
      showPlanPkgFlag: false,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  openAlertInfoModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCodeManualInfo,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  openPdfModal = async () => {
    await this.setState({
      showImageFlag: false,
    });
    let msg = {
      Header: "Shipping Label",
      Body: {
        s3Url: this.state.labelurl,
        fileName: this.state.labelTrack,
        deviceSize: this.state.deviceSize,
        footnote: "",
      },
      Footer: "",
    };

    this.props.showModal(
      {
        open: true,
        title: "Alert - Show",
        message: msg,
        closeModal: this.closeModal,
      },
      "showPdf"
    );
  };

  returnToShipPagi = () => {
    this.props.returnToCaller();
  };

  handleExit = async () => {
    if (this.state.shippingDoneFlag && this.state.showLabelFlag) {
      await this.handleCloseShipping();
    }
    if (this.state.shipType === "manual" && this.state.manualCancel) {
      await this.handleCloseShipping();
    }
    this.returnToShipPagi();
  };

  handleShipperType = async (e) => {
    await this.setState({
      shippingHandlerType: e.target.value,
    });
  };

  manualPkgTypeHandler = async (e) => {
    await this.setState({
      manualPkgType: e.target.value,
    });
  };

  handleScaleType = async (e) => {
    await this.setState({
      scaleType: e.target.value,
    });
  };

  // Check to see if the package has already been shipped.
  isThisPackageShipped = async () => {
    await this.getThisInvoice();
    let canShip = true;
    let numberPreviouslyShipped = 0;
    // console.log('this.state.invoice.spInfo.regularPkg:', this.state.invoice.spInfo.regularPkg);
    this.state.invoice.spInfo.regularPkg.forEach((elm) => {
      if (elm.regularCarrier && elm.regularCarrier.carrierName !== "")
        numberPreviouslyShipped++;
    });
    return { canShip, numberPreviouslyShipped };
  };

  handleActionType = async (e) => {
    let value = e.target.value;
    if (value === "packing") {
      this.setState({
        actionType: e.target.value,
        message: "",
        errFlag: false,
        newRates: [],
      });
    } else {
      let msg = "";
      let errFlag = false;
      let preShipInfo = await this.isThisPackageShipped();

      if (preShipInfo.numberPreviouslyShipped > 0) {
        msg =
          preShipInfo.numberPreviouslyShipped +
          " package has already been shipped.";
        // errFlag = true;
        errFlag = false;
      }

      if (value === "shipping" && this.state.shipType === "regular") {
        if (
          this.state.packageHeight === 0 ||
          this.state.packageWidth === 0 ||
          this.state.pkgLength === 0
        ) {
          msg += "; Must provide all dimensions to ship.";
          errFlag = true;
        }
        if (
          this.state.packageWeight === 0 &&
          this.state.packageWeightOzs === 0
        ) {
          msg += "; Must provide all dimensions to ship.";
          errFlag = true;
        }

        this.setState({
          actionType: e.target.value,
          message: msg,
          errFlag,
        });
      }

      if (value === "shipping" && this.state.shipType === "manual") {
        if (this.state.manualPkgType === "parcel") {
          if (
            this.state.packageHeight === 0 ||
            this.state.packageWidth === 0 ||
            this.state.pkgLength === 0
          ) {
            msg += "; Must provide all dimensions to ship.";
            errFlag = true;
          }
          if (
            this.state.packageWeight === 0 &&
            this.state.packageWeightOzs === 0
          ) {
            msg += "; Must provide all dimensions to ship.";
            errFlag = true;
          }
        }

        this.setState({
          actionType: e.target.value,
          message: msg,
          errFlag,
        });
      }
    }
  };

  handlePackingCategory = async (e) => {
    await this.setState({
      packingCategory: e.target.value,
    });
  };

  handleCloseShipping = async () => {
    let url = baandaServer + closeShippingInvoice;
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      invoiceId: this.state.invoice.invoiceId,
    };
    // console.log("url:", url, " input:", input);
    try {
      let clret = await axios.post(url, input);

      if (clret.data.status === "success") {
        if (!this.state.shippingDoneFlag) {
          this.returnToShipPagi();
        } else {
          this.setState({
            message: "Will remove from ship list after shipping.",
            errFlag: false,
          });
        }
      } else {
        this.setState({
          message: clret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  onChangeDimension = async (e) => {
    let name = [e.target.name];
    let value = e.target.value;
    let fieldName = name[0];
    let message = "";
    let errFlag = false;
    // console.log("name:", name);
    // console.log("value:", value);
    // console.log("fieldName:", fieldName);
    if (fieldName === "thePkgNumber") {
      let pkglen = 0,
        pkgwidth = 0,
        pkgheight = 0,
        pkgweight = 0,
        pkgweightozs = 0;

      this.state.invoice.spInfo.regularPkg.forEach((obj) => {
        if (parseFloat(obj.packageNo) === parseFloat(value)) {
          pkglen = obj.length;
          pkgwidth = obj.width;
          pkgheight = obj.height;
          pkgweight = obj.weight;
          if (obj.weightozs) pkgweightozs = obj.weightozs;
          if (obj.regularCarrier && obj.regularCarrier.carrierName !== "") {
            message = "This package has already been shipped.";
            errFlag = true;
          }
        }
      });
      this.setState({
        packageWidth: pkgwidth,
        pkgLength: pkglen,
        packageHeight: pkgheight,
        packageWeight: pkgweight,
        packageWeightOzs: pkgweightozs,
        message,
        errFlag,
      });
    }

    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(1);
      let val = parseFloat(tmp);

      await this.setState({
        [e.target.name]: val,
        message: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        message: "",
      });
    }
  };

  onChangeWeight = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(1);
      let val = parseFloat(tmp);

      await this.setState({
        [e.target.name]: val,
        message: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        message: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleCarrierSelected = async () => {
    let value = document.getElementById("shipCarrierSelect").value;

    let selectedCarrier = value;
    let selectedCarrierAc = "";
    if (value === "USPS") selectedCarrierAc = usps_account;
    if (value === "UPS") selectedCarrierAc = ups_account;
    if (value === "DHL") selectedCarrierAc = dhl_account;
    await this.setState({
      selheight: 1,
      selectedCarrierAc,
      selectedCarrier,
      standardPkgList: [],
    });
  };

  handlePkgSelected = async () => {
    let value = document.getElementById("shipPackageSelect").value;

    let valobj = JSON.parse(value);

    await this.setState({
      selheightpkg: 1,
      selectedPackage: valobj,
      pkgLength: valobj.pkgLength,
      packageHeight: valobj.pkgHeight,
      packageWidth: valobj.pkgWidth,
      packageWeight: valobj.pkgWeight,
      packageWeightOzs: valobj.pkgWeightOzs,
      scaleType: valobj.scaleType,
    });
  };

  handleStandardPkgSelected = async () => {
    let value = document.getElementById("shipStandardPackage").value;

    let valobj = JSON.parse(value);

    await this.setState({
      selheightpkg: 1,
      selectedStandardPackage: valobj,
    });
  };

  onfocus = async () => {
    await this.setState({
      selheight: 5,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  onfocuspkg = async () => {
    await this.setState({
      selheightpkg: 5,
    });
  };

  onblurpkg = async () => {
    this.setState({
      selheightpkg: 1,
    });
  };

  handlePackingConfirm = async () => {
    this.setState((prevstate) => ({
      packingConfirm: !prevstate.packingConfirm,
    }));
  };

  handleShippingRateBuy = async () => {
    this.setState((prevstate) => ({
      confirmRate: !prevstate.confirmRate,
    }));
  };

  handleManualClose = async () => {
    this.setState((prevstate) => ({
      manualCancel: !prevstate.manualCancel,
    }));
  };

  handleShippingDone = async () => {
    this.setState((prevstate) => ({
      shippingDoneFlag: !prevstate.shippingDoneFlag,
    }));
  };

  handleRemoveShipping = async () => {
    this.setState((prevstate) => ({
      closeShipFlag: !prevstate.closeShipFlag,
    }));
  };

  handleShippingCCBuy = async () => {
    this.setState((prevstate) => ({
      confirmDirectCCBuy: !prevstate.confirmDirectCCBuy,
    }));
  };

  validatePacking = async () => {
    let isValid = "success";
    let msg = "";
    if (this.state.packingCategory === "regular") {
      if (
        this.state.pkgHeight === 0 ||
        this.state.pkgLength === 0 ||
        this.state.pkgWeight === 0 ||
        this.state.pkgWidthx === 0
      ) {
        isValid = "error";
        msg = "All dimensions must be non-zero";
      }
    }
    if (this.state.packingCategory === "special") {
      if (
        this.state.splPkgHeight === 0 ||
        this.state.splPkgLength === 0 ||
        this.state.splPkgWeight === 0 ||
        this.state.splPkgWidth === 0
      ) {
        isValid = "error";
        msg = "All dimensions must be non-zero";
      }
      if (this.state.splPkgWeight === 0 && this.state.splPkgWeightOzs === 0) {
        isValid = "error";
        msg += "Weight cannot be zero (special). ";
      }
    }

    return { status: isValid, Msg: msg };
  };

  handlePackagingSave = async () => {
    let check = await this.validatePacking();
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    if (check.status === "success") {
      let input;
      if (this.state.packingCategory === "regular") {
        input = {
          totalNumberOfPkg: parseFloat(this.state.totalNumberOfPkg),
          thePkgNumber: parseFloat(this.state.thePkgNumber),

          scaleType: this.state.scaleType,
          length: parseFloat(this.state.pkgLength),
          width: parseFloat(this.state.packageWidth),
          height: parseFloat(this.state.packageHeight),
          weight: parseFloat(this.state.packageWeight),
          weightOzs: parseFloat(this.state.packageWeightOzs),
          clientProgram: "HandleShippingDetails",
          clientFunction: "handlePackagingSave",
          packingCategory: this.state.packingCategory,
          communityId: this.props.communityId,
          invoiceId: this.state.invoice.invoiceId,
          coopStoreId,
        };
      }
      let url = baandaServer + savePackingDone;

      try {
        let packret = await axios.post(url, input);

        if (packret.data.status === "success") {
          await this.setState({
            message: "Successfully updated.",
            errFlag: false,
            invoice: packret.data.Msg,
          });
        } else {
          await this.setState({
            message: packret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("handlePackageSaving err:", err.message);
        await this.setState({
          message: err.message,
          errFlag: true,
        });
      }
    } else {
      await this.setState({
        message: check.Msg,
        errFlag: true,
      });
    }
  };

  onfocusa = async () => {
    this.setState({
      selheightad: 4,
    });
  };

  onblura = async () => {
    this.setState({
      selheightad: 1,
    });
  };

  handleFromAddSelect = async () => {
    let value = document.getElementById("fromAddressShip").value;

    let data = JSON.parse(value);

    let fromAddress = data.address;
    this.setState({
      fromAddress,
      selheightad: 1,
    });
  };

  formShipmentInput = async () => {
    let fromAddr;

    let toPhone = "";
    if (this.state.invoice && this.state.invoice.deliveryData) {
      toPhone = this.state.invoice.deliveryData.phone;
    }

    this.setState({
      toPhone,
    });

    if (
      this.state.invoice &&
      this.state.invoice.invoiceOrigin === "manual-shipping"
    ) {
      let company = "";
      if (this.props.store) company = this.props.store.storeName;
      else company = this.state.invoice.deliveryData.fromAddress.shipFromName;
      fromAddr = {
        verify: ["delivery"],
        street1: this.state.invoice.deliveryData.fromAddress.street1,
        street2: this.state.invoice.deliveryData.fromAddress.street2,
        city: this.state.invoice.deliveryData.fromAddress.city,
        state: this.state.invoice.deliveryData.fromAddress.state,
        zip: this.state.invoice.deliveryData.fromAddress.zip,
        country: this.state.invoice.deliveryData.fromAddress.country,
        company,
        phone: this.state.invoice.customerCell,
      };
    } else {
      let company = "";
      if (this.props.store) company = this.props.store.storeName;
      else company = this.props.commName;
      fromAddr = {
        verify: ["delivery"],
        street1: this.state.fromAddress.street1,
        street2: this.state.fromAddress.street2,
        city: this.state.fromAddress.city,
        state: this.state.fromAddress.state,
        zip: this.state.fromAddress.zip,
        country: this.state.fromAddress.country,
        company,
        phone: this.props.auth.user.cell.number,
      };
    }

    let toAddr;
    if (this.state.invoice && this.state.invoice.invoiceOrigin === "catalog") {
      if (this.state.invoice.invoiceRaw) {
        // console.log("In here ... ");
        let ca = this.state.invoice.invoiceRaw.deliveryData;
        toAddr = {
          verify: ["delivery"],
          street1: ca.street1,
          street2: ca.street2,
          city: ca.city,
          state: ca.state,
          zip: ca.zip,
          country: ca.country,
          company: this.state.invoice.customerName,
          phone: toPhone,
        };
      } else {
        let ca = this.state.invoice.deliveryData;
        toAddr = {
          verify: ["delivery"],
          street1: ca.street1,
          street2: ca.street2,
          city: ca.city,
          state: ca.state,
          zip: ca.zip,
          country: ca.country,
          company: this.state.invoice.customerName,
          phone: toPhone,
        };
      }
    }
    if (
      this.state.invoice &&
      this.state.invoice.invoiceOrigin === "manual-shipping"
    ) {
      let ca = this.state.invoice.deliveryData.toAddress;
      toAddr = {
        verify: ["delivery"],
        street1: ca.street1,
        street2: "",
        city: ca.city,
        state: ca.state,
        zip: ca.postalCode,
        country: ca.country,
        company: this.state.invoice.customerName,
        phone: toPhone,
      };
    }

    if (this.state.invoice && this.state.invoice.invoiceOrigin === "manual") {
      let ca = this.state.invoice.manualInvoiceParams.shipToAddress;
      toAddr = {
        verify: ["delivery"],
        street1: ca.line1,
        street2: "",
        city: ca.city,
        state: ca.state,
        zip: ca.postalCode,
        country: ca.country,
        company: this.state.invoice.customerName,
        phone: toPhone,
      };
    }

    let parcel;
    if (this.state.shipType === "regular") {
      parcel = {
        length: parseFloat(this.state.pkgLength),
        width: parseFloat(this.state.packageWidth),
        height: parseFloat(this.state.packageHeight),
        weight:
          parseFloat(this.state.packageWeight) * 16 +
          parseFloat(this.state.packageWeightOzs),
      };
    }

    let manulPkgType = "";
    if (this.state.shipType === "manual") {
      if (this.state.manualPkgType === "standard") {
        parcel = {
          predefined_package: this.state.selectedStandardPackage,
          weight: parseFloat(this.state.packageWeight),
        };
        manulPkgType = "standard";
      }
      if (this.state.manualPkgType === "media") {
        parcel = {
          length: parseFloat(this.state.pkgLength),
          width: parseFloat(this.state.packageWidth),
          height: parseFloat(this.state.packageHeight),
          weight:
            parseFloat(this.state.packageWeight) * 16 +
            parseFloat(this.state.packageWeightOzs),
        };
        manulPkgType = "media";
      }
      if (this.state.manualPkgType === "parcel") {
        parcel = {
          length: parseFloat(this.state.pkgLength),
          width: parseFloat(this.state.packageWidth),
          height: parseFloat(this.state.packageHeight),
          weight:
            parseFloat(this.state.packageWeight) * 16 +
            parseFloat(this.state.packageWeightOzs),
        };
        manulPkgType = "parcel";
      }
    }

    let carrierAc = this.state.selectedCarrierAc;
    let input = {
      fromAddr,
      toAddr,
      parcel,
      carrierAc,
      shipId: "",
      shipType: this.state.shipType,
      manulPkgType,
      rate: 0,
      communityId: this.props.communityId,
      clientProgram: "HandleShippingDetails",
      clientFunction: "handleGetShipping",
    };

    return input;
  };

  handleGetShipping = async () => {
    this.setState({
      loadingFlag: true,
    });

    let input = await this.formShipmentInput();

    input.clientFunction = "handleGetShipping";

    try {
      let url = baandaServer + getShippingRates;

      let shipret = await axios.post(url, input);

      if (shipret.data.status === "success") {
        if (shipret.data.Msg.length > 0) {
          let newRates = [];
          shipret.data.Msg.forEach((nrt) => {
            if (!this.state.toPhone || this.state.toPhone === "") {
              if (nrt.service !== "NextDayAirEarlyAM") newRates.push(nrt);
            } else newRates.push(nrt);
          });
          await this.setState({
            // newRates: shipret.data.Msg,
            newRates,
            message: "",
            errFlag: false,
            showSelectedRate: false,
          });
        } else {
          let msg = "";
          if (this.state.selectedCarrier === "DHL")
            msg =
              "DHL provides shipping rates only if origin is domestic and destination is international.";
          await this.setState({
            newRates: shipret.data.Msg,
            message: msg,
            errFlag: false,
            showSelectedRate: false,
          });
        }
      } else {
        await this.setState({
          message: shipret.data.Msg,
          errFlag: true,
          showSelectedRate: false,
        });
      }
    } catch (err) {
      console.log("handleGetShipping err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
        showSelectedRate: false,
        confirmRate: false,
      });
    }

    await this.setState({
      loadingFlag: false,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  selectThisRate = async (obj) => {
    let selectedRateMsg = "Service: " + obj.service + "; ";
    if (isNaN(obj.delivery_days)) {
      selectedRateMsg += " Delivery " + obj.delivery_days + "; ";
    } else {
      selectedRateMsg += "Delivery in " + obj.delivery_days + " days; ";
    }
    selectedRateMsg += " Price is $" + obj.newRate;

    this.setState({
      selectedRateMsg,
      selectedRateObj: obj,
      selectedRate: obj.newRate,
      showSelectedRate: true,
      directShipmentBuyflag: false,
      confirmRate: false,
    });
  };

  inputForPostCredit = async () => {
    let baandaCommunity = await this.getCommunityInfo(BaandaCommunityId);
    let businessCommunity = await this.getCommunityInfo(this.props.communityId);
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let input = {
      shipId: this.state.selectedRateObj.shipment_id,
      rate: this.state.selectedRateObj.newRate,
      easyPostRate: this.state.selectedRateObj.Rate,
      baandaBuyPostageRate: this.state.selectedRateObj.Rate,
      clientProgram: "HandleShippingDetails",
      clientFunction: "handleShippingBuyViaCredit",
      communityId: parseFloat(this.props.communityId),
      coopStoreId,
      commFinMMDD: businessCommunity.finMMDD,
      commFinYYYY: businessCommunity.finYYYY,
      BaandaCommunityId: parseFloat(BaandaCommunityId),
      baandaComFinMMDD: baandaCommunity.finMMDD,
      baandaComFinYYYY: baandaCommunity.finYYYY,
      baandaId: this.props.auth.user.baandaId,
      baandaName: this.props.auth.user.name,
      baandaEmail: this.props.auth.user.email,
      invoiceId: this.state.invoice.invoiceId,
      selectedCarrier: this.state.selectedCarrier,
      thePkgNumber: this.state.thePkgNumber,
      totalNumberOfPkg: this.state.thePkgNumber,
      selectedService: this.state.selectedRateObj.service,
      selectedRateId: this.state.selectedRateObj.rateId,
      buyType: "regularShipping",
    };

    return input;
  };

  handleShippingBuyViaCredit = async () => {
    let baseInp = await this.inputForPostCredit();
   

    this.setState({
      loadingFlag: true,
    });

    try {
      let url = baandaServer + postUseCredits;
      // console.log("url:", url, " baseInp:", baseInp);
      let shipBuy = await axios.post(url, baseInp);
      // console.log("shipBuy.data:", shipBuy.data);
      if (shipBuy.data.status === "success") {
        if (shipBuy.data.Msg.transStatus) {
          if (shipBuy.data.Msg.transStatus === "notok") {
            this.setState({
              directShipmentBuyflag: true,
              showSelectedRate: false,
              insufficientMsg: shipBuy.data.Msg.note,
              showLabelFlag: false,
              buyResponse: null,
              accStatus: shipBuy.data.Msg.accStatus,
              accMsg: shipBuy.data.Msg.accMsg,
              labelurl: "",
              labelTrack: "",
              trackerurl: "",
            });
          } else {
            if (
              shipBuy.data.Msg.buyResponse &&
              shipBuy.data.Msg.buyResponse.status === "success"
            ) {
              let closeShipFlag = this.state.closeShipFlag;
              let shippingDoneFlag = this.state.shippingDoneFlag;

              if (this.state.shipType === "manual") {
                closeShipFlag = true;
                shippingDoneFlag = true;
              }
              this.setState({
                directShipmentBuyflag: false,
                showSelectedRate: false,
                insufficientMsg: "",
                message: "Shipment label successfully acquired",
                showLabelFlag: true,
                buyResponse: shipBuy.data.Msg.buyResponse.Msg,
                accStatus: shipBuy.data.Msg.buyResponse.Msg.accStatus,
                accMsg: shipBuy.data.Msg.accMsg,
                labelurl: shipBuy.data.Msg.buyResponse.Msg.label_url,
                labelTrack:
                  "Track Id: " + shipBuy.data.Msg.buyResponse.Msg.trackId,
                public_url: shipBuy.data.Msg.buyResponse.Msg.public_url,
                showActionType: false,
                showHandledBy: false,
                showSelectRates: false,
                showShippingRates: false,
                showCCBuy: false,
                closeShipFlag,
                shippingDoneFlag,
              });
            } else {
              console.log("We got an error on buyResponse :", shipBuy.data.Msg);
              await this.setState({
                message: shipBuy.data.Msg,
                errFlag: true,
              });
            }
          }
        }
      } else {
        this.setState({
          message: shipBuy.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("HandleShippingBuy err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
    this.setState({
      loadingFlag: false,
    });
  };

  handleShippingBuyViaCC = async () => {
    // console.log("in handle via credit card .. handleShippingBuyViaCC.");
    let baandaCommunity = await this.getCommunityInfo(BaandaCommunityId);
    let businessCommunity = await this.getCommunityInfo(this.props.communityId);

    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    await this.getServiceFee();

    let packageDef = {
      length: this.state.pkgLength,
      width: this.state.packageWidth,
      height: this.state.packageHeight,
      weight: parseFloat(this.state.packageWeight),
      weightOzs: parseFloat(this.state.packageWeightOzs),
      scaleType: this.state.pkgScaleType,
    };

    let input = {
      communityId: parseFloat(this.props.communityId),
      coopStoreId,
      commFinMMDD: businessCommunity.finMMDD,
      commFinYYYY: businessCommunity.finYYYY,
      baandaId: this.props.auth.user.baandaId,
      baandaEmail: this.props.auth.user.email,
      baandaCell: this.props.auth.user.cell.number,
      baandaName: this.props.auth.user.name,
      paymentMedium: "creditcard",
      StripeAccId: baandaCommunity.stripe.id,
      description: "Payment for individual shipping",
      paymentAmount: parseFloat(this.state.selectedRateObj.newRate),
      baandaBuyPostageRate: this.state.selectedRateObj.Rate,
      BaandaCommunityId: parseFloat(BaandaCommunityId),
      baandaComFinMMDD: baandaCommunity.finMMDD,
      baandaComFinYYYY: baandaCommunity.finYYYY,
      clientProgram: "HandleShippingDetails",
      clientFunction: "handleShippingBuyViaCC",
      note:
        "Shipment packing is " +
        this.state.packingCategory +
        " via " +
        this.state.selectedCarrier +
        " || " +
        this.state.selectedRateMsg,
      csFee: this.state.csFee,
      StripePaymentId: "",
      selectedRateObj: this.state.selectedRateObj,
      selectedRateMsg: this.state.selectedRateMsg,
      selectedCarrier: this.state.selectedCarrier,
      invoiceId: this.state.invoice.invoiceId,
      thePkgNumber: parseFloat(this.state.thePkgNumber),
      totalNumberOfPkg: this.state.totalNumberOfPkg,
      shippingDoneFlag: this.state.shippingDoneFlag,
      selectedService: this.state.selectedRateObj.service,
      selectedRateId: this.state.selectedRateObj.rateId,
      shipType: this.state.shipType,
      buyType: "regularShipping",
      packageDef,
    };

 

    this.setState({
      buyUsingCCFlag: true,
      input,
      shipmentPrice: parseFloat(this.state.selectedRateObj.newRate),
    });
  };

  handleBackFromPayment = async (status) => {
    // console.log("status:", status);
    let showLabelFlag = false;
    let showActionType = true;
    let showHandledBy = true;
    let showSelectRates = true;
    let showShippingRates = true;
    let showCCBuy = true;
    let message = "";
    let labelurl = ""; //: shipBuy.data.Msg.buyResponse.Msg.label_url,
    let labelTrack = ""; // "Track Id: " + shipBuy.data.Msg.buyResponse.Msg.trackId,
    if (status === "success") {
      await this.getThisInvoice();
      showLabelFlag = true;
      showActionType = false;
      showHandledBy = false;
      showSelectRates = false;
      showShippingRates = false;
      showCCBuy = false;
      message = "Shipment label successfully aquired";
      if (this.state.shipType === "regular") {
        this.state.invoice.spInfo.regularPkg.forEach((elm) => {
          if (
            parseFloat(elm.packageNo) === parseFloat(this.state.thePkgNumber)
          ) {
            labelurl = elm.regularCarrier.label_url;
            labelTrack = "Track Id: " + elm.regularCarrier.trackingId;
          }
        });
      }
      if (this.state.shipType === "manual") {
        labelurl =
          this.state.invoice.spInfo.regularPkg[0].regularCarrier.label_url;
        labelTrack =
          "Track Id: " +
          this.state.invoice.spInfo.regularPkg[0].regularCarrier.trackingId;
      }
    }
    let closeShipFlag = this.state.closeShipFlag;
    let shippingDoneFlag = this.state.shippingDoneFlag;
    // console.log("this.state.shipType:", this.state.shipType);
    if (this.state.shipType === "manual" && labelurl !== "") {
      closeShipFlag = true;
      shippingDoneFlag = true;
    }
    await this.setState({
      buyUsingCCFlag: false,
      showLabelFlag,
      showActionType,
      showHandledBy,
      showSelectRates,
      showShippingRates,
      showCCBuy,
      message,
      labelurl,
      labelTrack,
      closeShipFlag,
      shippingDoneFlag,
    });
    if (this.state.shipType === "manual" && this.state.shippingDoneFlag) {
      await this.handleCloseShipping();
    }
  };

  handleSelfSpecialShipping = async () => {
    let businessCommunity = await this.getCommunityInfo(this.props.communityId);
    // console.log("got business community...");
    try {
      let url = baandaServer + saveSelfSpecialShipping;
      // console.log("url:", url);

      let shippingType = "self";
      if (this.state.packingCategory === "special") shippingType = "special";
      let input = {
        thePkgNumber: this.state.thePkgNumber,
        totalNumberOfPkg: this.totalNumberOfPkg,
        carrierName: this.state.selfCarrierName,
        trackingId: this.state.selfTrackingno,
        trackingUrl: this.state.selfTrackingurl,
        baandaId: this.props.auth.user.baandaId,
        baandaName: this.props.auth.user.name,
        note: this.state.selfPostNote,
        clientProgram: "HandleShippingDetails",
        clientFunction: "handleSelfSpecialShipping",
        communityId: parseFloat(this.props.communityId),
        commFinMMDD: businessCommunity.finMMDD,
        commFinYYYY: businessCommunity.finYYYY,
        invoiceId: this.state.invoice.invoiceId,
        shippingPrice: parseFloat(this.state.selfPostPrice),
        shippingDate: this.state.shipDate,
        paymentMedium: "cash",
        paymentAmount: parseFloat(this.state.selfPostPrice), // shippingPrice
        shippingType,
      };
      // console.log("input:", input);
      let splret = await axios.post(url, input);
      // console.log("splret.data:", splret.data);
      if (splret.data.status === "success") {
        await this.setState({
          specialSelfMsg: "Successfully Updated. Please Exit.",
        });
      } else {
        await this.setState({
          specialSelfMsg: "Error: " + splret.Msg,
        });
      }
    } catch (err) {
      await this.setState({
        specialSelfMsg: "Error: " + err.message,
      });
    }
  };

  handledob = async (date) => {
    await this.setState({
      shipDate: date,
    });
  };

  handleSelectPacketType = async () => {
    // console.log(
    //   "in handleSelectPacketType this.state.selectedCarrier:",
    //   this.state.selectedCarrier
    // );
    let standardPkgList = [];
    if (this.state.selectedCarrier === "USPS") {
      // console.log("in here ...usps");
      standardPkgList = [
        "Card",
        "Letter",
        "Flat",
        "FlatRateEnvelope",
        "FlatRateLegalEnvelope",
        "FlatRatePaddedEnvelope",
        "FlatRateGiftCardEnvelope",
        "FlatRateWindowEnvelope",
        "FlatRateCardboardEnvelope",
        "SmallFlatRateEnvelope",
      ];
    }
    if (this.state.selectedCarrier === "UPS") {
      // console.log("in here ... ups");
      standardPkgList = [
        "UPSLetter",
        "UPSExpressBox",
        "UPS25kgBox",
        "UPS10kgBox",
        "Tube",
        "Pak",
        "SmallExpressBox",
        "MediumExpressBox",
        "LargeExpressBox",
      ];
    }

    if (this.state.selectedCarrier === "DHL") {
      // console.log("in here ... dhl");
      standardPkgList = [
        "UPSExpressBox",
        "UPS25kgBox",
        "UPS10kgBox",
        "Tube",
        "Pak",
        "SmallExpressBox",
        "MediumExpressBox",
        "LargeExpressBox",
      ];
    }

    this.setState({
      standardPkgList,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("HandleShippingDetails...");

    // Upload Spinner _____________________________________________
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <ReactLoading
          type={"spokes"}
          color={"#237547"}
          height={25}
          width={25}
          className="comm-entry-spinner"
        />
      );
    } else {
      uploadingSpin = null;
    }
    // Upload Panel _______________________________________________

    let exitButton = (
      <button
        onClick={this.handleExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let modalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let manualInfoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertInfoModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let pkgDoneSaveButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handlePackagingSave}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let showRatesButton;
    if (this.state.selectedCarrier !== "" && this.state.fromAddress) {
      showRatesButton = (
        <button
          className="btn_reg_100"
          type="button"
          onClick={this.handleGetShipping}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Show Rates
        </button>
      );
    }

    let showLabelButton;
    if (this.state.showLabelFlag) {
      showLabelButton = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.openPdfModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Label
        </button>
      );
    }

    let invoiceCustomerPanel;
    invoiceCustomerPanel = (
      <div className="row">
        <div className="col-3 text-center ship-handle-invoiceId">
          Invoice #: <b>{this.props.invoice.invoiceId}</b>
        </div>
        <div className="col-9 text-left ship-handle-customer">
          Customer: <b>{this.props.invoice.customerName}</b>&nbsp;
          {this.props.invoice.customerEmail === ""
            ? ""
            : "- (" + this.props.invoice.customerEmail + ")"}
        </div>
      </div>
    );

    let customerAddressPanel;
    if (this.state.invoice) {
      if (this.state.invoice.invoiceOrigin === "manual-shipping") {
        let ca = this.state.invoice.deliveryData.toAddress;
        customerAddressPanel = (
          <div className="row">
            <div className="col-3 text-Right ship-handle-address">
              Ship to address
            </div>
            <div className="col-9 text-left ship-handle-customer">
              {ca.street1}
              ,&nbsp;
              {ca.city}, {ca.state}&nbsp;{ca.postalCode}&nbsp;&nbsp;{ca.country}
            </div>
          </div>
        );
      } else if (this.state.invoice.invoiceOrigin === "manual") {
        let ca = this.state.invoice.manualInvoiceParams.shipToAddress;
        customerAddressPanel = (
          <div className="row">
            <div className="col-3 text-Right ship-handle-address">
              Ship to address
            </div>
            <div className="col-9 text-left ship-handle-customer">
              {ca.line1}
              ,&nbsp;
              {ca.city}, {ca.state}&nbsp;{ca.postalCode}&nbsp;&nbsp;{ca.country}
            </div>
          </div>
        );
      } else {
        let ca = this.state.invoice.deliveryData;

        customerAddressPanel = (
          <div className="row">
            <div className="col-3 text-Right ship-handle-address">
              Ship to address
            </div>
            <div className="col-9 text-left ship-handle-customer">
              {ca.street1}
              {ca.street2 === "" ? null : " " + ca.street2},&nbsp;
              {ca.city}, {ca.state}&nbsp;{ca.zip}&nbsp;&nbsp;{ca.country}
            </div>
          </div>
        );
      }
    }

    let adrlist;
    if (this.state.fromAddressList.length > 1) {
      adrlist = this.state.fromAddressList.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.name}&nbsp;
          </option>
        );
      });
    }

    let fromAddressPanel;
    if (this.state.fromAddressList.length === 1) {
      if (this.state.fromAddress) {
        if (this.state.invoice.invoiceOrigin === "manual-shipping") {
          let fa = this.state.fromAddress.address;

          fromAddressPanel = (
            <div className="text-center ship-from-addr">
              From: {fa.street1} {fa.street2 === "" ? null : ", " + fa.street2},{" "}
              {fa.city} {fa.state}, {fa.zip}
            </div>
          );
        } else {
          let fa = this.state.fromAddress;

          fromAddressPanel = (
            <div className="text-center ship-from-addr">
              From: {fa.street1} {fa.street2 === "" ? null : ", " + fa.street2},{" "}
              {fa.city} {fa.state}, {fa.zip}
            </div>
          );
        }
      }
    } else {
      fromAddressPanel = (
        <div className="ship-addr-from-pos">
          <div className="row ship-addr-dropdown">
            <div className="col text-center ">
              <select
                size={this.state.selheightad}
                id="fromAddressShip"
                name="fromAddressShip"
                onChange={this.handleFromAddSelect}
                className="ship-addr-dd-text-xx"
                onFocus={this.onfocusa}
                onBlur={this.onblura}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  From the address
                </option>
                {adrlist}
              </select>
            </div>
          </div>
        </div>
      );
    }

    let stuffForShipping = [];

    let itemsDisplayPanel;
    let bool = true;
    let planType = "";
    let plannedPack = null;

    if (this.state.invoice && this.state.invoice.invoiceRaw) {
      this.state.invoice.invoiceRaw.invoiceLineItems.forEach((elm) => {
        if (elm.itemType === "goods") {
          if (
            this.state.invoice.deliveryData &&
            this.state.invoice.deliveryData.periodicShipData
          ) {
            if (
              this.state.invoice.deliveryData.periodicShipData.type ===
              "planned"
            ) {
              elm.planType =
                this.state.invoice.deliveryData.periodicShipData.type;
              elm.plannedPack =
                this.state.invoice.deliveryData.periodicShipData.plannedPack;
            }
          }
          stuffForShipping.push(elm);
        }
      });
    }
    if (this.state.invoice && this.state.invoice.invoiceOrigin === "manual") {
      let dimensions = null;
      if (this.state.invoice && this.state.invoice.spInfo.regularPkg[0]) {
        dimensions = {
          length: this.state.invoice.spInfo.regularPkg[0].length,
          width: this.state.invoice.spInfo.regularPkg[0].width,
          height: this.state.invoice.spInfo.regularPkg[0].height,
          weight:
            this.state.invoice.spInfo.regularPkg[0].weight * 16 +
            this.state.invoice.spInfo.regularPkg[0].weightOzs,
          scaleType: this.state.invoice.spInfo.regularPkg[0].scaleType,
        };
      }
      let stuffforShipObj = {
        itemName: this.state.invoice.spInfo.regularItems[0].name,
        dimensions,
        quantity: "na",
        unitType: "na",
        planType,
        plannedPack,
      };
      stuffForShipping.push(stuffforShipObj);
    }
    if (
      this.state.invoice &&
      this.state.invoice.invoiceOrigin === "manual-shipping"
    ) {
      let dimensions = null;
      if (this.state.invoice && this.state.invoice.spInfo.regularPkg[0]) {
        dimensions = {
          length: this.state.invoice.spInfo.regularPkg[0].length,
          width: this.state.invoice.spInfo.regularPkg[0].width,
          height: this.state.invoice.spInfo.regularPkg[0].height,
          weight:
            this.state.invoice.spInfo.regularPkg[0].weight * 16 +
            this.state.invoice.spInfo.regularPkg[0].weightOzs,
          scaleType: this.state.invoice.spInfo.regularPkg[0].scaleType,
        };
      }
      let stuffforShipObj = {
        itemName: this.state.invoice.itemDetails[0].itemName,
        dimensions,
        quantity: "1",
        unitType: "Pkg",
        planType,
        plannedPack,
      };
      stuffForShipping.push(stuffforShipObj);
    }

    if (
      this.state.invoice &&
      this.state.invoice.invoiceOrigin === "catalog" &&
      this.state.invoice.periodicDeliveryObj
    ) {
      let dimensions;
      if (
        this.state.invoice.periodicDeliveryObj.periodicShipData.type ===
        "planned"
      ) {
        let bp =
          this.state.invoice.periodicDeliveryObj.periodicShipData
            .plannedPkgDimension;
        dimensions = {
          length: bp.length,
          width: bp.width,
          height: bp.height,
          weight: bp.weight,
          scaleType: "fps",
        };
      } else {
        if (
          this.state.invoice.spInfo &&
          this.state.invoice.spInfo.regularPkg.length > 0
        ) {
          let arrPointer = this.state.invoice.spInfo.regularPkg.length - 1;
          if (arrPointer >= 0) {
            let bp = this.state.invoice.spInfo.regularPkg[arrPointer];
            dimensions = {
              length: bp.length,
              width: bp.width,
              height: bp.height,
              weight: bp.weight,
              scaleType: "fps",
            };
          }
        }
      }

      let stuffforShipObj = {
        itemName: this.state.invoice.periodicDeliveryObj.lineItemName,
        dimensions,
        quantity: "1",
        unitType: "Pkg",
      };
      stuffForShipping.push(stuffforShipObj);
    }

    if (
      this.state.invoice &&
      this.state.invoice.invoiceOrigin === "subscription-paid" &&
      this.state.invoice.invoiceCategory === "periodic-delivery"
    ) {
      let dimensions = null;
      if (
        this.state.invoice.periodicDeliveryData &&
        this.state.invoice.periodicDeliveryData.periodicShipData &&
        this.state.invoice.periodicDeliveryData.periodicShipData &&
        this.state.invoice.periodicDeliveryData.periodicShipData.type ===
          "boxed"
      ) {
        let bp =
          this.state.invoice.periodicDeliveryData.periodicShipData.boxedPack
            .size;
        dimensions = {
          length: bp.length,
          width: bp.width,
          height: bp.height,
          weight: bp.weight,
          scaleType: "fps",
        };
      }

      if (
        this.state.invoice.periodicDeliveryObj &&
        this.state.invoice.periodicDeliveryObj.periodicShipData &&
        this.state.invoice.periodicDeliveryObj.periodicShipData &&
        this.state.invoice.periodicDeliveryObj.periodicShipData.type ===
          "planned"
      ) {
        let bp =
          this.state.invoice.periodicDeliveryData.periodicShipData
            .plannedPkgDimension;
        dimensions = {
          length: bp.length,
          width: bp.width,
          height: bp.height,
          weight: bp.weight,
          scaleType: "fps",
        };
      }
      let stuffforShipObj = {
        itemName: this.state.invoice.periodicDeliveryObj.lineItemName,
        dimensions,
        quantity: "1",
        unitType: "Pkg",
      };
      stuffForShipping.push(stuffforShipObj);
    }

    let showPlanPkgPanel;
    if (this.state.showPlanPkgFlag) {
      showPlanPkgPanel = (
        <div className="show-plan-box">
          <div className="text-center ship-show-pkg-plan">
            Plan Contains&nbsp;&nbsp;
            <button
              className="btn_back_main"
              type="button"
              onClick={this.returnFromPlanPkg}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div>
            {this.state.showPlanDtl.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={`${
                      bool
                        ? "row color-ship-pkgline-dr"
                        : "color-ship-pkgline-lt"
                    }`}
                  >
                    <div className="row">
                      <div className="col text-left ship-line-items-name">
                        {i}.&nbsp;{obj}
                      </div>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (this.state.invoice) {
      itemsDisplayPanel = (
        <div>
          <div className="row">
            <div className="col-8 text-center ship-line-items-name-head">
              Invoice Line Item (to ship)
            </div>
            <div className="col-2 text-left ship-line-items-dim-head">
              Pkg. Size
            </div>
            <div className="col-2 text-left ship-line-items-qty-head">
              Qty (unit)
            </div>
          </div>
          <div className="fixedsize-ship-lineitem">
            {stuffForShipping.map((obj, i) => {
              let diml = '"';
              let dimw = "ozs";
              let dimExists = true;
              if (obj.dimensions) {
                if (obj.dimensions.scaleType === "mks") {
                  diml = "cm";
                  dimw = "gm";
                }
              } else dimExists = false;

              return (
                <div key={i}>
                  <div className={`${bool ? "row-color-xx" : "row-color-zz"}`}>
                    <div className="row">
                      <div className="col-8 text-left ship-line-items-name">
                        {obj.itemName}{" "}
                        {obj.planType === "planned" ? (
                          <button
                            className="btn_reg_30"
                            onClick={() => this.showPlanPack(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            O
                          </button>
                        ) : null}
                      </div>

                      {dimExists ? (
                        <div className="col-2 text-left ship-line-items">
                          {obj.dimensions.length}
                          {diml}, {obj.dimensions.width}
                          {diml}, {obj.dimensions.height}
                          {diml}, {obj.dimensions.weight}
                          {dimw}
                        </div>
                      ) : (
                        <div className="col-2 text-left ship-line-items">
                          None specified
                        </div>
                      )}

                      <div className="col-2 text-left ship-line-items">
                        {obj.quantity} ({obj.unitType})
                      </div>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let selectRateButton = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.handleShippingBuyViaCredit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Buy
      </button>
    );

    let selectDirectButton = (
      <button
        className="btn_reg_80"
        type="button"
        onClick={this.handleShippingBuyViaCC}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        CC-Buy
      </button>
    );

    let selectedRatePanel;
    if (
      this.state.showSelectedRate &&
      !this.state.directShipmentBuyflag &&
      this.state.actionType === "shipping"
    ) {
      selectedRatePanel = (
        <div className="text-center selected-rate-row-pos">
          <div className="row">
            <div className="col-1 text-right selected-rate-lbl">Selected: </div>
            <div className="col-7 text-left selected-rate-text">
              {this.state.selectedRateMsg}
            </div>
            <div className="col-2 text-left selected-rate-text">
              <Checkbox
                checked={this.state.confirmRate}
                onChange={this.handleShippingRateBuy}
              />{" "}
              Confirm Buy&nbsp;{uploadingSpin}
            </div>
            <div className="col-2 text-left selected-rate-lbl">
              {this.state.confirmRate ? selectRateButton : null}
            </div>
          </div>
        </div>
      );
    }

    let directCCBuyPanel;
    if (
      this.state.directShipmentBuyflag &&
      !this.state.showSelectedRate &&
      this.state.actionType === "shipping" &&
      this.state.showCCBuy
    ) {
      directCCBuyPanel = (
        <div className="text-center selected-rate-row-pos">
          <div className="row">
            <div className="col-1 text-right selected-rate-lbl">Options: </div>
            <div className="col-7 text-left selected-rate-text">
              {this.state.insufficientMsg}
            </div>
            <div className="col-2 text-left select-option-decision">
              <Checkbox
                checked={this.state.confirmDirectCCBuy}
                onChange={this.handleShippingCCBuy}
              />{" "}
              Confirm CC
            </div>
            <div className="col-2 text-left selected-rate-lbl">
              {this.state.confirmDirectCCBuy ? selectDirectButton : null}
            </div>
          </div>
        </div>
      );
    }

    bool = true;
    let rateToDisplayPanel;
    if (
      this.state.newRates.length > 0 &&
      this.state.actionType === "shipping" &&
      this.state.showShippingRates
    ) {
      rateToDisplayPanel = (
        <div>
          <div className="row">
            <div className="col-2 text-center ship-line-items-dim-head">
              Ops
            </div>
            <div className="col-10 text-left ship-line-items-dim-head">
              Service || Delivery days || Rate [Select a service to pay/ship]
            </div>
          </div>
          <div className="fixedsize-ship-lineitem">
            {this.state.newRates.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={`${bool ? "row-color-xxrt" : "row-color-zzrt"}`}
                  >
                    <div className="row rate-row-pos">
                      <div className="col-2 text-left ">
                        <button
                          className="btn_reg_40"
                          type="button"
                          onClick={() => this.selectThisRate(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-check" />
                        </button>
                      </div>
                      <div className="col-10 text-left ship-rate-list-text">
                        {obj.service} || {obj.delivery_days} || $
                        {this.commaFormattedCurrency(obj.newRate)}
                      </div>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let actionTypePanel;
    if (
      this.state.packingCategory === "regular" &&
      this.state.showActionType &&
      !this.state.specialShippingOnlyFlag
    ) {
      actionTypePanel = (
        <div className="row ship-handler-type-row">
          <div className="col-8 text-center radio-font-style">
            <strong>Action type&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="packing"
                  checked={this.state.actionType === "packing"}
                  onChange={this.handleActionType}
                />{" "}
                Packing
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="shipping"
                  checked={this.state.actionType === "shipping"}
                  onChange={this.handleActionType}
                />{" "}
                Shipping
              </label>
            </div>
          </div>
          <div className="col-4 text-left available-credit-msg">
            Available Credit $
            {this.commaFormattedCurrency(
              this.props.community.credits.creditAvailable
            )}
          </div>
        </div>
      );
    }

    let mksFpsPanel;

    let pkgCategoryPanel;
    if (
      this.state.invoice &&
      this.state.invoice.spSpecialExists &&
      !this.state.specialShippingOnlyFlag
    ) {
      pkgCategoryPanel = (
        <div className="row ship-handler-type-row">
          <div className="col text-center radio-font-style">
            <strong>Shipping Category&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="regular"
                  checked={this.state.packingCategory === "regular"}
                  onChange={this.handlePackingCategory}
                />{" "}
                Regular
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="special"
                  checked={this.state.packingCategory === "special"}
                  onChange={this.handlePackingCategory}
                />{" "}
                Special
              </label>
            </div>
          </div>
        </div>
      );
    }

    let dim, weight;
    if (this.state.scaleType === "fps") {
      dim = "Inchs";
      weight = "oz";
    } else {
      dim = "cm";
      weight = "gm";
    }

    let shippingCompletePanel;
    if (this.state.shipType === "regular") {
      shippingCompletePanel = (
        <div className="row ship-done-line-pos">
          {this.state.closeShipFlag ? (
            <div className="col-6 text-right shipping-complete-check">
              &nbsp;
            </div>
          ) : (
            <div className="col-6 text-right shipping-complete-check">
              <Checkbox
                checked={this.state.shippingDoneFlag}
                onChange={this.handleShippingDone}
              />{" "}
              Complete shipping for this invoice
            </div>
          )}

          <div className="col-6 text-left shipping-complete-check">
            <Checkbox
              checked={this.state.closeShipFlag}
              onChange={this.handleRemoveShipping}
            />{" "}
            CLOSE & REMOVE FINAL: Confirm&nbsp;&nbsp;
            {this.state.closeShipFlag ? (
              <button
                className="btn_reg_60_rb ship-done-pos"
                type="button"
                onClick={this.handleCloseShipping}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Close
              </button>
            ) : (
              ""
            )}
            &nbsp;
          </div>
        </div>
      );
    }

    let pkglist;
    if (this.state.defaultPkgs.length > 0) {
      pkglist = this.state.defaultPkgs.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.pkgName}&nbsp;
          </option>
        );
      });
    }

    let defaultPkgPanel;
    if (this.state.defaultPkgs.length > 0) {
      defaultPkgPanel = (
        <div>
          <select
            size={this.state.selheightpkg}
            onFocus={this.onfocuspkg}
            onBlur={this.onblurpkg}
            id="shipPackageSelect"
            name="shipPackageSelect"
            onChange={this.handlePkgSelected}
            onSelect={this.handlePkgSelected}
            className="ship_select_packages"
            defaultValue={"DEFAULT"}
          >
            <option className="" value="DEFAULT" disabled>
              Select default pkg
            </option>
            {pkglist}
          </select>
        </div>
      );
    } else {
      defaultPkgPanel = (
        <div className="text-center ship-default-pkg-not-set">
          No default pkgs defined.
        </div>
      );
    }

    let stdPkgList;
    let stdPkgPanel;
    if (this.state.standardPkgList.length > 0) {
      stdPkgList = this.state.standardPkgList.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj}&nbsp;
          </option>
        );
      });

      stdPkgPanel = (
        <div>
          <select
            size={this.state.selheightpkg}
            onFocus={this.onfocuspkg}
            onBlur={this.onblurpkg}
            id="shipStandardPackage"
            name="shipStandardPackage"
            onChange={this.handleStandardPkgSelected}
            onSelect={this.handleStandardPkgSelected}
            className="ship_select_std_packages"
            defaultValue={"DEFAULT"}
          >
            <option className="" value="DEFAULT" disabled>
              Select Standard Package Type
            </option>
            {stdPkgList}
          </select>
        </div>
      );
    }

    let parcelInputPanel;
    if (
      this.state.actionType === "packing" &&
      this.state.packingCategory === "regular" &&
      !this.state.specialShippingOnlyFlag
      // &&
      // this.state.shipType === "regular"
    ) {
      parcelInputPanel = (
        <div>
          <div className="text-center packing-header">Parcel Dimensions</div>
          <div className="row">
            <div className="col-6 text-right pkg-dim-lbl">This Package No</div>
            <div className="col-6 text-left">{this.state.thePkgNumber}</div>
          </div>
          <div className="row pkg-row-pos">
            <div className="col-4">{defaultPkgPanel}</div>
            <div className="col-8">
              <div className="row pkg-row-pos">
                <div className="col-3 text-right pkg-dim-lbl">Length</div>
                <div className="col-3 text-left">
                  <input
                    name="pkgLength"
                    type="number"
                    value={this.state.pkgLength}
                    onChange={this.onChangeDimension}
                    className="ship-dimension-text"
                    step=".1"
                    placeholder="0"
                    autoComplete="off"
                    spellCheck="false"
                  />
                  &nbsp;<font size="1">{dim}</font>
                </div>
                <div className="col-3 text-right pkg-dim-lbl">Width</div>
                <div className="col-3 text-left">
                  <input
                    name="packageWidth"
                    type="number"
                    value={this.state.packageWidth}
                    onChange={this.onChangeDimension}
                    // size="10"
                    className="ship-dimension-text"
                    step=".1"
                    placeholder="0"
                    autoComplete="off"
                    spellCheck="false"
                  />
                  &nbsp;<font size="1">{dim}</font>
                </div>
              </div>
              <div className="row pkg-row-pos">
                <div className="col-3 text-right pkg-dim-lbl">Height</div>
                <div className="col-3 text-left">
                  <input
                    name="packageHeight"
                    type="number"
                    value={this.state.packageHeight}
                    onChange={this.onChangeWeight}
                    // size="10"
                    className="ship-dimension-text"
                    step="1"
                    placeholder="0"
                    autoComplete="off"
                    spellCheck="false"
                  />
                  &nbsp;<font size="1">{dim}</font>
                </div>
                <div className="col-1 text-right pkg-dim-lbl">Weight</div>
                <div className="col-5 text-center">
                  <input
                    name="packageWeight"
                    type="number"
                    value={this.state.packageWeight}
                    onChange={this.onChangeWeight}
                    // size="10"
                    className="ship-weight-text"
                    step="1"
                    placeholder="0"
                    autoComplete="off"
                    spellCheck="false"
                  />
                  &nbsp;<font size="1">Lbs</font>&nbsp;&nbsp;&nbsp;
                  <input
                    name="packageWeightOzs"
                    type="number"
                    value={this.state.packageWeightOzs}
                    onChange={this.onChangeWeight}
                    // size="10"
                    className="ship-weight-text"
                    step="1"
                    placeholder="0"
                    autoComplete="off"
                    spellCheck="false"
                  />
                  &nbsp;<font size="1">Ozs</font>
                </div>
              </div>
              <div className="row packing-save-row-pos">
                <div className="col-6 text-right pkg-confirm-lbl">
                  <Checkbox
                    checked={this.state.packingConfirm}
                    onChange={this.handlePackingConfirm}
                  />{" "}
                  Confirm Packaging Done
                </div>
                <div className="col-6 text-left pkg-confirm-lbl">
                  {this.state.packingConfirm ? pkgDoneSaveButton : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let carrierPanel;
    carrierPanel = (
      <div>
        <select
          size={this.state.selheight}
          onFocus={this.onfocus}
          onBlur={this.onblur}
          id="shipCarrierSelect"
          name="shipCarrierSelect"
          onChange={this.handleCarrierSelected}
          onSelect={this.handleCarrierSelected}
          className="ship_select_carrier"
          defaultValue={"DEFAULT"}
        >
          <option className="" value="DEFAULT" disabled>
            Select your carrier
          </option>
          <option className="" value="USPS">
            USPS
          </option>
          <option className="" value="UPS">
            UPS
          </option>
          {/* <option className="" value="DHL">
            DHL (Express)
          </option> */}
        </select>
      </div>
    );

    let manualPkgTypePanel;
    manualPkgTypePanel = (
      <div className="row manual-pkg-type-row">
        <div className="col text-center radio-font-style">
          <strong>Pkg Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="standard"
                checked={this.state.manualPkgType === "standard"}
                onChange={this.manualPkgTypeHandler}
              />{" "}
              Standard
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="parcel"
                checked={this.state.manualPkgType === "parcel"}
                onChange={this.manualPkgTypeHandler}
              />{" "}
              Parcel
            </label>
          </div>
          {this.state.selectedCarrier === "USPS" ? (
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="media"
                  checked={this.state.manualPkgType === "media"}
                  onChange={this.manualPkgTypeHandler}
                />{" "}
                Media-mail
              </label>
            </div>
          ) : null}
        </div>
      </div>
    );

    let packingPanel;
    if (
      this.state.actionType === "packing" &&
      this.state.packingCategory === "regular" &&
      !this.state.specialShippingOnlyFlag &&
      this.state.shipType === "regular"
    ) {
      packingPanel = (
        <div>
          {mksFpsPanel}
          {parcelInputPanel}
          {shippingCompletePanel}
        </div>
      );
    }

    let showSelectPkgButton;
    showSelectPkgButton = (
      <button
        className="btn_reg_100 packet-type-btn-pos"
        type="button"
        onClick={this.handleSelectPacketType}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Packet Type
      </button>
    );

    let parcelTypePanel;
    if (
      this.state.actionType === "packing" &&
      this.state.shipType === "manual"
    ) {
      parcelTypePanel = (
        <div>
          <div className="row">
            <div className="col-4">{carrierPanel}</div>
            <div className="col-8">{manualPkgTypePanel}</div>
          </div>
          {this.state.manualPkgType !== "standard"
            ? parcelInputPanel
            : showSelectPkgButton}
          {this.state.manualPkgType === "standard" &&
          this.state.standardPkgList.length > 0 ? (
            <div className="row">
              <div className="col-6 text-center">{stdPkgPanel}</div>
              <div className="col-6">
                <b>Weight</b>{" "}
                <input
                  name="packageWeight"
                  type="number"
                  value={this.state.packageWeight}
                  onChange={this.onChangeWeight}
                  // size="10"
                  className="ship-dimension-stdpkg-text"
                  step="1"
                  placeholder="0"
                  autoComplete="off"
                  spellCheck="false"
                />
                &nbsp;<font size="2">{weight}</font>
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    if (
      this.state.actionType === "packing" &&
      this.state.shipType === "manual"
    ) {
      packingPanel = (
        <div>
          <div className="row text-center ship-manual-pkg-header">
            <div className="col-8 text-right">
              <u>Manual Package</u>&nbsp;&nbsp;{manualInfoButton}
            </div>
            <div className="col-4 text-left remove-item-close-pos">
              <Checkbox
                checked={this.state.manualCancel}
                onChange={this.handleManualClose}
              />{" "}
              <font color="red">Remove Item & Close</font>
            </div>
          </div>
          <div className="manual-parcel-choice-row">{parcelTypePanel}</div>
          {shippingCompletePanel}
        </div>
      );
    }

    let specialSelfPanel;
    if (
      this.state.shippingHandlerType === "self" ||
      this.state.packingCategory === "special" ||
      this.state.specialShippingOnlyFlag
    ) {
      specialSelfPanel = (
        <div className="text-center special-self-pos">
          <div className="text-center special-self-header">
            Special or Self Shipping
          </div>
          <div className="row spcl-row-pos">
            <div className="col-4 text-right self-post-lbl">Carrier Name</div>
            <div className="col-8 text-left">
              <input
                name="selfCarrierName"
                type="text"
                value={this.state.selfCarrierName}
                onChange={this.onChange}
                size="80"
                maxLength="80"
                className="self-post-carrier"
                placeholder="Carrier Name"
              />
            </div>
          </div>
          <div className="row spcl-row-pos">
            <div className="col-4 text-right self-post-lbl">Tracking No</div>
            <div className="col-8 text-left">
              <input
                name="selfTrackingno"
                type="text"
                value={this.state.selfTrackingno}
                onChange={this.onChange}
                size="40"
                maxLength="40"
                className="self-post-carrier-trackingno"
                placeholder="Tracking #"
              />
            </div>
          </div>
          <div className="row spcl-row-pos">
            <div className="col-4 text-right self-post-lbl">Tracking URL</div>
            <div className="col-8 text-left">
              <input
                name="selfTrackingurl"
                type="text"
                value={this.state.selfTrackingurl}
                onChange={this.onChange}
                size="150"
                maxLength="150"
                className="self-post-carrier-trackingurl"
                placeholder="Tracking url (optional)"
              />
            </div>
          </div>
          <div className="row spcl-row-pos">
            <div className="col-4 text-right self-post-lbl">Posting price</div>
            <div className="col-8 text-left">
              <input
                name="selfPostPrice"
                type="number"
                value={this.state.selfPostPrice}
                onChange={this.onChangeWeight}
                className="self-post-price"
                step=".1"
                placeholder="0"
                autoComplete="off"
                spellCheck="false"
              />{" "}
              &nbsp;Note: Expense it in Finance-Expense
            </div>
          </div>
          <div className="row spcl-row-pos">
            <div className="col-4 text-right self-post-lbl">Note</div>
            <div className="col-8 text-left">
              <input
                name="selfPostNote"
                type="text"
                value={this.state.selfPostNote}
                onChange={this.onChange}
                size="200"
                maxLength="200"
                className="self-post-note"
                placeholder="Ref. Note (optional)"
              />
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn_reg_70"
              type="button"
              onClick={this.handleSelfSpecialShipping}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Save
            </button>
          </div>
          <div className="text-center self-special-msg">
            {this.state.specialSelfMsg}
          </div>
        </div>
      );
    }

    let shippingHadlerPanel;
    if (
      this.state.actionType === "shipping" &&
      this.state.packingCategory === "regular" &&
      this.state.showHandledBy
    ) {
      shippingHadlerPanel = (
        <div className="row ship-handler-type-row">
          <div className="col text-center radio-font-style">
            <strong>Handled by&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="baanda"
                  checked={this.state.shippingHandlerType === "baanda"}
                  onChange={this.handleShipperType}
                />{" "}
                Baanda-shipping
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="self"
                  checked={this.state.shippingHandlerType === "self"}
                  onChange={this.handleShipperType}
                />{" "}
                Self-shipping
              </label>
            </div>
          </div>
        </div>
      );
    }

    let carrierRatePanel;
    if (
      this.state.actionType === "shipping" &&
      this.state.showSelectRates &&
      this.state.shipType === "regular" &&
      !this.state.errFlag
    ) {
      carrierRatePanel = (
        <div>
          <div className="row">
            <div className="col-4 text-center ship-from-addr-lbl">
              {carrierPanel}
            </div>

            <div className="col-3 text-center ship-from-addr-lbl">
              {fromAddressPanel}
            </div>
            <div className="col-1 text-center spinner-pos-ship-buy">
              {uploadingSpin}
            </div>
            <div className="col-4 text-center ship-from-rates-pos">
              {showRatesButton}
            </div>
          </div>
        </div>
      );
    }

    if (
      this.state.actionType === "shipping" &&
      this.state.showSelectRates &&
      this.state.shipType === "manual"
    ) {
      carrierRatePanel = (
        <div>
          <div className="row">
            <div className="col-4 text-center ship-from-addr-lbl">
              {this.state.selectedCarrier}
            </div>

            <div className="col-3 text-center ship-from-addr-lbl">
              {fromAddressPanel}
            </div>
            <div className="col-1 text-center spinner-pos-ship-buy">
              {uploadingSpin}
            </div>
            <div className="col-4 text-center ship-from-rates-pos">
              {showRatesButton}
            </div>
          </div>
        </div>
      );
    }

    let shippingAdminPanel;
    if (this.state.actionType === "shipping") {
      shippingAdminPanel = <div>{carrierRatePanel}</div>;
    }

    let outputPanel;

    if (this.state.buyUsingCCFlag) {
      outputPanel = (
        <div className="shipping-handling-box">
          {/* Will have CC panel here */}
          <PayForCCPlatform
            deviceSize={this.state.deviceSize}
            paymentAmount={parseFloat(this.state.shipmentPrice)}
            handleExit={this.handleBackFromPayment}
            inputData={this.state.input}
            caller="individulpost"
          />
        </div>
      );
    }

    if (
      this.state.shippingHandlerType === "baanda" &&
      !this.state.buyUsingCCFlag
    ) {
      outputPanel = (
        <div className="shipping-handling-box">
          <div className="text-center ">
            <div className="row">
              <div className="col text-center shipping-header">
                Ship Items &nbsp;{exitButton}&nbsp;{modalButton}
              </div>
            </div>
          </div>
          {invoiceCustomerPanel}
          {customerAddressPanel}
          {itemsDisplayPanel}
          {showPlanPkgPanel}
          {actionTypePanel}
          {pkgCategoryPanel}
          {shippingHadlerPanel}
          {shippingAdminPanel}
          {packingPanel}
          {/* {shippingCompletePanel} */}
          {rateToDisplayPanel}
          {selectedRatePanel}
          {directCCBuyPanel}
          {showLabelButton}
          <div
            className={
              this.state.errFlag
                ? "text-center shipping-det-msg-err"
                : "text-center shipping-det-msg"
            }
          >
            {this.state.message}
          </div>
        </div>
      );
    }

    if (
      (this.state.shippingHandlerType === "self" ||
        this.state.packingCategory === "special") &&
      !this.state.buyUsingCCFlag
    ) {
      outputPanel = (
        <div className="shipping-handling-box">
          <div className="text-center ">
            <div className="row">
              <div className="col-6 text-center shipping-header">
                Ship Items &nbsp;{exitButton}&nbsp;{modalButton}
              </div>
              <div className="col-2 text-right ship-date-lbl date-col-pos">
                Ship Date
              </div>
              <div className="col-4 text-left date-col-pos">
                <DatePicker
                  selected={this.state.shipDate}
                  onChange={this.handledob}
                  onSelect={this.handledob}
                  name="shipDate"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </div>
          </div>
          {invoiceCustomerPanel}
          {customerAddressPanel}
          {itemsDisplayPanel}
          {shippingHadlerPanel}
          {/* {selfShippingPanel} */}
          {pkgCategoryPanel}
          {specialSelfPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center shipping-det-msg-err"
                : "text-center shipping-det-msg"
            }
          >
            {this.state.message}
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

HandleShippingDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HandleShippingDetails));
